import React, { useContext, useEffect, useRef, useState } from 'react';
// Context
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
// Controllers
import { TimeoutController } from '../Controllers';
// Trackers
import { WidgetTracker } from './Widget';
import { MainTracker } from './Main';
// Const, Configs & Interfaces
import { ActivityOptionalData, ActivityTrackerProps, IBaseTrackerProps } from '../interfaces';
import { HIDE, IconStyles, REVEAL, WITHOUT_NAVIGATION } from '../Constants';
import { ALL_ELEMENTS } from '../Config/Layouts';
// Misc
import { isGivenMorphTypeFormRelated, StringValueValidator } from '../misc';
import { isSlotMachineRelatedAction } from '@common/userMessagesUtils';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { ValidatorFunctions } from '@common/Validators';
import eventBus from '@common/eventBus';
import { t } from 'i18next';
import { useUserContext } from '@contexts/user';
import { useRootContext } from '@contexts/RootContext';

// This container component makes sure the right tracker is being rendered.
// There can be as much trackers as needed depending on the sys requirements.
// F.e. for the widget environment, WidgetTracker component is being rendered with its
//  own elements and useEffects inside WHILE for the main screen, MainTracker is
//  being rendered with its own useEffects.
// The separation is achieved via keeping the rendering logic (props.UI) and its relevant core tools (handleActivityChange, etc.) the same among trackers AND separated
// custom useEffects that will be called based on needed business logic and elements that each tracker has.

let LOG_LEVEL = "DEFAULT"; // set to "DEBUG" for full logs
const log = (mark, ...args) => LOG_LEVEL === "DEBUG" ? console.log(`÷ TrackerFather [ ${mark} ]`, ...args) : null;

// Each Tracker has their own elements defined in InitialValues.ts.
export const TrackerFather = (props: ActivityTrackerProps & IBaseTrackerProps) => {
  const { handleActivityChange, getActivityIndex, activityArrRef, checkIfShown,  } = props;
  const { isOperator } = useUserContext();

  const errandContext = useContext<IErrandContext>(ErrandContext);
  const { allConsentsGiven } = useRootContext();
  // used for action element check (by MorphType)
  const currMorphType = errandContext.morphType;
  
  // Related to Action/Workflow element logic.
  // Used for checking the last active user action in chat
  // in order to not to rerender the same action element over and over again.
  const lastChatActionId = useRef(null);

  // used for consent element.
  const [hasUserGivenConsent, setHasUserGivenConsent] = useState(allConsentsGiven ? 'true' : 'false');

  /**
   * Takes care of 'consent' activity changes. (current & delayed)
   */
  useEffect(() => {
    const setupConsentEventListener = () => {
      eventBus.on('consentGiven', () => {
        setHasUserGivenConsent('true');
      });
    };
    const removeConsentEventListener = () => {
      eventBus.remove('consentGiven');
    };
    // setup given consent event listener
    setupConsentEventListener();
    // remove on unmount
    return () => {
      removeConsentEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const showConsent = () => handleActivityChange(ALL_ELEMENTS.Consent, REVEAL);
    const hideConsent = () => handleActivityChange(ALL_ELEMENTS.Consent, HIDE);

    // Always hide on operator because sometimes hasUserGivenConsent
    // value could be undefined even if user is operator.
    if (isOperator) {
      hideConsent();
      return;
    }

    if (ValidatorFunctions.isUndefinedOrNull(hasUserGivenConsent) === true || hasUserGivenConsent === 'false') {
      // if null or undefined, show it.
      showConsent();
    } else {
      // if it has some value (not null AND not undefined)
      // check it to be the valid value of string and not empty
      // if not valid, it will fire console.error with description what validation failed.
      const valid = StringValueValidator(hasUserGivenConsent).run();
      if (valid) {
        // check it to be true
        // only in this case - hide it
        if (hasUserGivenConsent === 'true') {
          // hide it
          hideConsent();
        }
      } else {
        // if not valid value ("" or not string at all)
        hideConsent();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserGivenConsent, isOperator]);

  // take care of action/workflow incoming messages (current actions in chat) (current & delayed)
  // Action Element logic.
  useEffect(() => {
    const activityIdx = getActivityIndex(ALL_ELEMENTS.Action);
    const activityData = activityArrRef.current[activityIdx];
    const isActionActivityShown = activityData.isShown;
    const sameAsLastOne = lastChatActionId.current === props.errand?.action?.action?._id;
    const currActionDescription = props.errand?.action?.action?.fieldAttribute?.description;

    const isActionUploadRelated = currActionDescription === 'DOCUMENT' || currActionDescription === 'PHOTO' || currActionDescription === 'MAINPHOTO';

    // if editing mode is shown AND some action is shown, hide that action.
    if (props.editingMode) {
      // handleActivityChange automatically checks whether action el is shown or not.
      handleActivityChange(ALL_ELEMENTS.Action, HIDE, WITHOUT_NAVIGATION);
      return;
    }

    // if current action is slot machine related,
    // do nothing in terms of action handling.
    // because we have separate component for that.
    if (isSlotMachineRelatedAction(props.errand?.action?.action)) {
      return;
    }

    // if current action is ESign related,
    // do not show anything
    if (isGivenMorphTypeFormRelated(currMorphType) === true) {
      // handleActivityChange automatically checks whether action el is shown or not.
      handleActivityChange(ALL_ELEMENTS.Action, HIDE, WITHOUT_NAVIGATION);
      return;
    }

    // This flag is used for not switching to the incoming action element IF any of the following is present:
    // --- editing mode, private mode, consent element is shown or user has not yet given consent, current morphType is not none.
    // When withNavigationOvveride is False ---> it means that the action element will reveal BUT the activityTracker will not navigate to this new element (in other words, it will be just revealed in the background).
    // IF value is True ---> it means that Activity Tracker will reveal AND navigate to this new action element
    const withNavigationOvveride = !(
      props.editingMode ||
      // case for consent, now null consent means user has not given consent.
      checkIfShown(ALL_ELEMENTS.Consent) || hasUserGivenConsent === 'false' || hasUserGivenConsent === null || 
      isGivenMorphTypeFormRelated(currMorphType) === true ||
      // edge case when action comes in before ExpandFullMode is shown.
      // do not navigate to action element UNTILL ExpandFullMode is actually shown
      (props.isWidget === true && checkIfShown(ALL_ELEMENTS.ExpandFullMode) === false)
    );

    // if currentAction is not null/undefined AND its not the same as last one.
    if (props.errand?.action?.action) {
      const actionOptionalData: ActivityOptionalData = {
        icon: props.errand?.action?.action?.actionIcon,
        text: props.errand?.action?.action?.description,
      };

      if (isActionUploadRelated === true) {
        actionOptionalData.icon = <UploadFileIcon style={{ ...IconStyles.default }} />;
        actionOptionalData.text = t('AT_uploadFile');
      }

      if (isActionActivityShown) {
        // if not the same as last one (previous user_action id)
        if (sameAsLastOne === false) {
          // hide and immidiately show new one with new action Data.
          // also if there is pending timeout to hide, clear it
          TimeoutController.checkAndClear(ALL_ELEMENTS.Action);
          handleActivityChange(ALL_ELEMENTS.Action, HIDE, WITHOUT_NAVIGATION);
        } else {
          // clear timeout for removal
          TimeoutController.checkAndClear(ALL_ELEMENTS.Action);
        }
      }

      // only if not the same as last one (previous user_action id)
      if (sameAsLastOne === false) {
        // show action element
        handleActivityChange(ALL_ELEMENTS.Action, REVEAL, withNavigationOvveride, actionOptionalData);
      }
    } else {
      // If null or undefined or last one
      // setTimeout if shown.
      // if activity is shown
      // setTimeout to hide it
      // because user ignores it.
      if (isActionActivityShown) {
        TimeoutController.set(
          ALL_ELEMENTS.Action,
          setTimeout(() => {
            handleActivityChange(ALL_ELEMENTS.Action, HIDE);
            lastChatActionId.current = null;
          }, TimeoutController.durations.action)
        );
      }
    }

    lastChatActionId.current = props.errand?.action?.action?._id;
  }, [props.errand?.action?.action?._id, props.editingMode, currMorphType]);

  // These are the base props that are shared between trackers,
  // each tracker will have these props available.
  const defaultProps = {
    currentActivityIndex: props.currentActivityIndex,
    handleActivityChange: props.handleActivityChange,
    AbortControllersRef: props.AbortControllersRef,
    getActivityIndex: props.getActivityIndex,
    activityArrRef: props.activityArrRef,
    checkIfShown: props.checkIfShown,
    UI: props.UI,
  };

  const Trackers = {
    Default: (
      <MainTracker
        ActivityOptionalDataStorage={props.ActivityOptionalDataStorage}
        isSlotMachineShown={props.isSlotMachineShown}
        operatorData={props.operatorData}
        privateMode={props.privateMode}
        editingMode={props.editingMode}
        errand={props.errand}
        authToken={props.authToken}
        {...defaultProps}
      />
    ),
    Widget: <WidgetTracker {...defaultProps} />,
  };
  
  if (props.isWidget) {
    log('TrackerFather | props.isWidget', props.isWidget);
    return Trackers.Widget;
  }

  return Trackers.Default;
};
