import { useRef, useEffect } from "react";

const useResizeObserver = (ref, callback, observe = { width: true, height: true }) => {
    const previousDimensions = useRef({ width: null, height: null });

    useEffect(() => {
        if (!ref?.current) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                const prevWidth = previousDimensions.current.width;
                const prevHeight = previousDimensions.current.height;

                const shouldNotifyWidth = observe.width && width !== prevWidth;
                const shouldNotifyHeight = observe.height && height !== prevHeight;

                if (shouldNotifyWidth || shouldNotifyHeight) {
                    previousDimensions.current = { 
                        width: observe.width ? width : prevWidth, 
                        height: observe.height ? height : prevHeight 
                    };
                    callback({
                        ...(observe.width && { width }),
                        ...(observe.height && { height }),
                    });
                }
            }
        });

        const element = ref.current;
        resizeObserver.observe(element);

        return () => {
            resizeObserver.unobserve(element);
            resizeObserver.disconnect();
        };
    }, [ref, callback, observe]);
};

export default useResizeObserver;
