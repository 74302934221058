import { useEffect } from "react";

function useClickOutside(
    refs: React.RefObject<HTMLElement>[],
    isMobile: boolean,
    callback: () => void
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent | TouchEvent) {
            // Ignore event if an input field is still focused (for mobile keyboard dismissal)
            if (document.activeElement instanceof HTMLInputElement) {
                return;
            }

            // Check if the click/tap was outside all provided refs
            const isOutside = refs.every(
                (ref) => ref.current && !ref.current.contains(event.target as Node)
            );

            if (isOutside) {
                callback();
            }
        }

        if (isMobile) {
            document.addEventListener("touchstart", handleClickOutside);
            return () => {
                document.removeEventListener("touchstart", handleClickOutside);
            };
        } else {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [refs, callback]);
}

export default useClickOutside;
