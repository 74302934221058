enum AvatarMutexState {
  Unlocked = 'Unlocked',
  Locked = 'Locked',
}

enum AvatarInstanceState {
  FetchingResource = 'FetchingResource', // Fetching avatar resources (S3 bucket link)
  LoadingVideo = 'LoadingVideo', // Fetching actual avatar data
  Playing = 'Playing', // Video is actively playing (renamed for clarity)
  Finished = 'Finished', // Video has ended (shortened for clarity)
  Collapsing = 'Collapsing', // Shrinking or fading out
  PendingUnmount = 'PendingUnmount', // Waiting for avatar wrapper state to determine unmounting
  Unmounted = 'Unmounted', // Avatar has been removed from the DOM
}

export { AvatarInstanceState, AvatarMutexState };
