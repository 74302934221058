import React, { useEffect, useState } from 'react';

import UPM_TranslationModule from '@components/MorphUserPromptsMenu/translations';
import { TWorkflowData_UPmenu } from '@components/MorphUserPromptsMenu/types';
import { TConversationFooterProps } from '@components/ConversationFooter';
import { useAppsMenuState } from './hooks/useAppsMenuState';
import { useErrandContext } from '@contexts/ErrandContext';
import { ValidatorFunctions } from '@common/Validators';
import { useRootContext } from '@contexts/RootContext';
import { useUserContext } from '@contexts/user';
import { delay, useWorkflowApi } from './utils';
import { useTranslation } from 'react-i18next';
import { MorphType } from '@common/MorphType';
import MenuContent from './MenuContent';
import eventBus from '@common/eventBus';
import { IAppsMenuState, useFooterContext } from '@contexts/FooterContext';
import { copyObj } from '@common/userMessagesUtils';
// import FooterPart from './FooterPart';

export const TOGGLE_ANIMATION_DURATION = 650;
export const TOGGLE_ANIMATION_LIST_REVEAL_HIDE_DURATION = 50;
export const MAIN_CONTAINER_PADDING_X_VALUE = 6;

interface IProps {
    cancelAction: (key: any, clear?: boolean, withMorphTypeChange?: boolean) => Promise<void>;
    conversationFooterRowRef: React.MutableRefObject<HTMLDivElement>;
    conversationFooterRef: React.MutableRefObject<HTMLDivElement>;
}

const DEBUG = false;
// @ts-ignore
const _debugTranslationLogs = (...args) => DEBUG === true && console.log(...args)

export const externalAMOpenEventChannel = 'AM_openMenu'; // channel id of external open application menu signal.
export const sendOpenApplicationMenuSignal = () => eventBus.dispatch(externalAMOpenEventChannel, null);

// Needed for any other component that want to listen to application menu state changes.
// This is done so that the state is not lifted very high in DOM tree to not to cause too many unnecessary rerenders.
// Intead, just use subscribe from this module, pass the callback function that 
// will be called with the updated state ON each apps menu state change.

/**
 * setState(newVal) ---> each subscriber fire with new value.
 */
export const AppsMenuExternalState = (() => {
    let state: IAppsMenuState | null = null;
    const callers: Record<string, (state: IAppsMenuState | null) => void> = {};
    const eventHandlers: Record<string, (payload: any) => void> = {};
    const EVENTS = {
        SET_SEARCH_MODE: "SET_SEARCH_MODE",
        SET_WAS_SEARCH_CANCELLED: "SET_WAS_SEARCH_CANCELLED",
        SET_SEARCH_VIEW: "SET_SEARCH_VIEW"
    } as const;

    type TEvent = keyof typeof EVENTS;

    let wasRecentlySelectedPrompt = {
        val: false
    };

    const selectedPromptTools = (() => {
        const lock = () => {
            wasRecentlySelectedPrompt.val = true;
        }

        const unlock = () => {
            wasRecentlySelectedPrompt.val = false;
        }

        const wasRecentlySelected = () => {
            return wasRecentlySelectedPrompt.val;
        }

        return { lock, unlock, wasRecentlySelected }
    })()

    const subscribe = (callerId: string, callerFn: (state: IAppsMenuState | null) => void) => {
        callers[callerId] = callerFn;
    };

    const fire = () => {
        for (const [key, callerFn] of Object.entries(callers)) {
            callerFn(state);
        }
    };

    const unsubscribe = (callerId: string) => {
        delete callers[callerId];
    };

    const setState = (newState: IAppsMenuState) => {
        state = newState;
        fire();
    };

    const updateState = (updater: (currentState: IAppsMenuState | null) => IAppsMenuState) => {
        state = updater(state);
        fire();
    };

    const onEvent = (eventType: TEvent, handler: (payload: any) => void) => {
        eventHandlers[eventType] = handler;
    };

    const dispatchEvent = (eventType: TEvent, payload: any) => {
        if (eventHandlers[eventType]) {
            eventHandlers[eventType](payload);
        }
    };

    const offEvent = (eventType: TEvent) => {
        delete eventHandlers[eventType];
    };

    return { state, subscribe, setState, updateState, unsubscribe, onEvent, dispatchEvent, offEvent, EVENTS, selectedPromptTools };
})();


export const AppsMenu = React.memo((props: IProps & TConversationFooterProps) => {
    const [highlightedList, setHighlightedList] = useState<boolean[]>([]); // manage highligh effect for the search logic
    const [visibleList, setVisibleList] = useState<boolean[]>([]); // Manage visibility for three items
    // Old, was meant to show the disclaimer popup above the send button for the consent reason.
    // Now, not needed.
    // const { returnConsentGiven, setClickedDisabledFeatures } = useRootContext();
    const userContext = useUserContext();

    // main render data
    // @ts-ignore
    const WorkflowApi = useWorkflowApi(userContext);
    const [listData, setListData] = useState<TWorkflowData_UPmenu[]>(WorkflowApi.get());
    const [parsedWorkflowsWithTranslations, setParsedWorkflowsWithTranslations] = useState(listData);
    const { chatInputFieldRef } = useFooterContext();
    // external state hook
    // appsMenuState related data
    const { appsMenuState, setAppsMenuState } = useAppsMenuState();
    const { morphType, errand, errandId, setMultipleFooterTypingAnimations } = useErrandContext();
    const { shown, displayed, listShown } = appsMenuState;
    const [errandActionId, errandActionActionId] = [errand?.action?._id, errand?.action?.action?._id];

    // action related
    const { isNotUndefinedNorNull } = ValidatorFunctions;
    const hasActiveAction = React.useMemo(
        () => isNotUndefinedNorNull(errandActionId) || isNotUndefinedNorNull(errandActionActionId),
        [errandActionId, errandActionActionId]
    );
    const isThereActionBeforeMountRef = React.useRef(hasActiveAction);

    // to manage animation cancellation
    const cancelTokenRef = React.useRef<{ cancelled: boolean; cleanup?: () => void }>({ cancelled: false });

    const { i18n, t } = useTranslation();
    // Cancel ongoing animations
    const cancelAnimations = () => {
        cancelTokenRef.current.cancelled = true;
        cancelTokenRef.current.cleanup?.();
    };

    const resetAnimations = () => {
        cancelTokenRef.current = { cancelled: false }; // Reset for future animations.
    }

    // sets the visible list [false, false, false] --> to [true, true, ...false] in loop manner with await of TOGGLE_ANIMATION_LIST_REVEAL_HIDE_DURATION pause
    const updateVisibleListSequentially = async (boolVal) => {
        for (let i = 0; i < visibleList.length; i++) {
            try {
                await delay(TOGGLE_ANIMATION_LIST_REVEAL_HIDE_DURATION, cancelTokenRef.current);
                setVisibleList((prev) => {
                    const cpy = [...prev];
                    for (let j = 0; j <= i; j++) {
                        cpy[j] = boolVal;
                    }
                    return cpy;
                });
            } catch {
                break; // Stop the loop if animation is cancelled
            }
        }
    }

    const immidiatelyHideList = () => {
        setVisibleList(new Array(listData.length).fill(false));
    }

    // reveal the apps menu in sequential manner.
    const handleShowList = () => {
        updateVisibleListSequentially(true);
    };

    // hide the apps menu in sequential manner.
    const handleHideList = () => {
        immidiatelyHideList();
    };

    // main close menu handler.
    const closeMenu = () => {
        setAppsMenuState((prev) => {
            return {
                ...prev,
                isOpened: false
            }
        });
    };

    const openMenu = React.useCallback(() => {
        // reset the selected prompt state
        AppsMenuExternalState.selectedPromptTools.unlock();
        setAppsMenuState((prev) => {
            return {
                ...prev,
                isOpened: true,
                searchWasCancelled: true,
                searchMode: false,
                searchView: false
            }
        });
    }, []);

    // AppsMenuExternalState Manager Setup.
    // Watches the state updates and manages the subscribers of that state,
    useEffect(() => {
        AppsMenuExternalState.setState({ ...appsMenuState });
    }, [appsMenuState])

    // when searchMode is on
    useEffect(() => {
        // search mode BUT NOT search view
        if(appsMenuState.searchMode && !appsMenuState.searchView) {
            chatInputFieldRef.current?.update('');
            props.cancelAction(null, true, false);
        }
    }, [appsMenuState.searchMode, appsMenuState.searchView, chatInputFieldRef, props.cancelAction])

    // Makes sure that the external components can change the apps menu state
    useEffect(() => {
        const { EVENTS } = AppsMenuExternalState;
        // Register event handler for "SET_SEARCH_MODE"
        const handleSetSearchMode = (payload: { value: boolean }) => {
            setAppsMenuState((prevAppsMenuState) => ({
                ...prevAppsMenuState,
                searchMode: payload?.value ?? false
            }));
        };

        // Register event handler for "SET_SEARCH_VIEW"
        const handleSetSearchView = (payload: { value: boolean }) => {
            setAppsMenuState((prevAppsMenuState) => ({
                ...prevAppsMenuState,
                searchView: payload?.value ?? false
            }));
        };

        // Register event handler for "SET_WAS_SEARCH_CANCELLED"
        const handleSetSearchWasCancelled = (payload: { value: boolean }) => {
            // if we are canceling the search --> make sure searchMode and searchView are false.
            if(payload?.value === true) {
                setAppsMenuState((prevAppsMenuState) => ({
                    ...prevAppsMenuState,
                    searchMode: false,
                    searchView: false,
                    searchWasCancelled: true
                }));
            } else {
                setAppsMenuState((prevAppsMenuState) => ({
                    ...prevAppsMenuState,
                    searchWasCancelled: false
                }));
            }
           
        };

        AppsMenuExternalState.onEvent(EVENTS.SET_SEARCH_MODE, handleSetSearchMode);
        AppsMenuExternalState.onEvent(EVENTS.SET_SEARCH_VIEW, handleSetSearchView);
        AppsMenuExternalState.onEvent(EVENTS.SET_WAS_SEARCH_CANCELLED, handleSetSearchWasCancelled);

        return () => {
            AppsMenuExternalState.offEvent(EVENTS.SET_SEARCH_MODE);
            AppsMenuExternalState.offEvent(EVENTS.SET_SEARCH_VIEW);
            AppsMenuExternalState.offEvent(EVENTS.SET_WAS_SEARCH_CANCELLED);
        };
    }, [setAppsMenuState]);

    useEffect(() => {
        if (appsMenuState.searchMode && !appsMenuState.searchView) {
            setMultipleFooterTypingAnimations({ items: [t('AM_Type_To_Search')], delay: -1, speed: 30, currentActiveItem: -1 });
        } else {
            setMultipleFooterTypingAnimations(null);
        }
    }, [setMultipleFooterTypingAnimations, appsMenuState.searchMode, appsMenuState.searchView, t('AM_Type_To_Search')])


    // updates the length of the visible list to properly control the fade in animation for all the components.
    useEffect(() => {
        if (displayed) {
            setVisibleList(new Array(listData.length).fill(listShown ? true : false));
        } else {
            setVisibleList(new Array(listData.length).fill(false));
        }
    }, [listData.length]); // in case if the list options change.


    // hasActiveAction changes handling
    useEffect(() => {
        if (isThereActionBeforeMountRef.current === true) {
            closeMenu();
            // clear out the flag
            isThereActionBeforeMountRef.current = false;
        } else {
            // leave the isThereActionBeforeMountRef as false
        }
    }, []);

    // handles action and menu interaction on open/close menu events.
    useEffect(() => {
        // if menu is opened
        if (appsMenuState.isOpened) {
            // if there is currently an action in chat
            if (hasActiveAction === true) {
                // if it wasn't one before mount
                // close the menu
                if (isThereActionBeforeMountRef.current === false) {
                    closeMenu();
                }
                // if it was before mount.
                // just cancel the current action in chat
                else {
                    props.cancelAction(null, true, false);
                }
            }
        }
        // if closed, update was action before flag.
        else {
            isThereActionBeforeMountRef.current = hasActiveAction;
        }
    }, [appsMenuState.isOpened, hasActiveAction])

    // handle animations on listShown toggle.
    useEffect(() => {
        if (displayed) {
            resetAnimations();
            if (listShown) {
                handleShowList();
            } else {
                handleHideList();
            }

            return () => {
                cancelAnimations();
            }
        } else {
            cancelAnimations();
            setTimeout(() => resetAnimations(), 0);
            setVisibleList(new Array(listData.length).fill(false));
        }
    }, [listShown, visibleList.length, displayed]);

    // handle morph type changes reflect on this menu component.
    // if some morphtype appears, close the menu at all times.
    useEffect(() => {
        if(morphType !== MorphType.None) {
            closeMenu();
            // make sure to handle the search view and search mode on morphType change.
            // this is needed IF user clicks on lang selector or any other morph change triggering element that 
            // opens some morphType in the convo footer.
            // make sure to cancel search
            setAppsMenuState((prev) => {
                return {
                    ...prev,
                    searchView: false,
                    searchMode: false,
                    searchWasCancelled: true
                }
            })
        }
    }, [morphType, errandId]);

    // Esc key press handler
    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                closeMenu();
            }
        };

        window.addEventListener('keydown', handleEscKey);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleEscKey);
        };
    }, []); // Empty dependency array ensures this runs once on mount

    // Load data on menu open.
    useEffect(() => {
        // when menu opens
        if (appsMenuState.isOpened) {
            // update the items from the api.
            WorkflowApi.refetch((errMsg) => console.error(errMsg));
        }
    }, [appsMenuState.isOpened])

    // Translation related logic.
    useEffect(() => {
        const handleTranslationsProcessed = () => {
            const newListData = WorkflowApi.parsedWorkflowsData;
            // if english, do nothing
            if (
                i18n.language === 'en' ||
                ValidatorFunctions.isTypeOfArray(newListData) === false ||
                newListData.length === 0
            ) {
                setListData(newListData);
                setParsedWorkflowsWithTranslations(newListData)
                return;
            }

            // patch with translations from translationsModule
            _debugTranslationLogs('🌎 before map loop all translations', UPM_TranslationModule.getAllTranslations())
            const localWorkflowsArrWithTranslations = newListData.map((wfData) => {
                // if no translation present, then there is some error in translation.
                // keep original version of message in this case.
                _debugTranslationLogs('🌎 wfData', wfData)
                const currTranslation = UPM_TranslationModule.get(UPM_TranslationModule.getWfKey(wfData));
                _debugTranslationLogs('🌎 currTranslation', currTranslation ?? wfData.name[i18n.language])
                return {
                    ...wfData,
                    name: {
                        ...wfData.name,
                        [i18n.language]: currTranslation ?? wfData.name[i18n.language],
                    },
                };
            });

            _debugTranslationLogs('🌎 localWorkflowsArrWithTranslations', localWorkflowsArrWithTranslations)

            setListData((_) => (localWorkflowsArrWithTranslations));
            setParsedWorkflowsWithTranslations(localWorkflowsArrWithTranslations);
        }

        // execute translation logic on initial call
        handleTranslationsProcessed();

        // Attach listeners
        eventBus.on('UPM_translationsProcessed', handleTranslationsProcessed as EventListener);

        // Cleanup listeners on unmount
        return () => {
            eventBus.remove('UPM_translationsProcessed', handleTranslationsProcessed as EventListener);
        };
    }, [i18n.language, appsMenuState.isOpened, WorkflowApi.parsedWorkflowsData]);

    useEffect(() => {

        const handleExternalOpenAppMenuSignal = () => {
            // if (!returnConsentGiven) {
            //     setClickedDisabledFeatures(true);
            //     return;
            // }
            if (appsMenuState.isOpened) return; // if opened already, do nothing

            openMenu();
        }

        eventBus.on(externalAMOpenEventChannel, handleExternalOpenAppMenuSignal);

        return () => {
            eventBus.remove(externalAMOpenEventChannel, handleExternalOpenAppMenuSignal);
        }
    }, [appsMenuState.isOpened, openMenu])

    // const actionIsDropdownRelated = props?.errand?.action?.fieldAttribute?.description === 'DROPDOWN' || props?.errand?.action?.action?.fieldAttribute?.description === 'DROPDOWN';
    // const displayFooterPart = morphType === MorphType.None && !actionIsDropdownRelated;

    return (
        <>
            <MenuContent
                shown={shown}
                listData={listData}
                displayed={displayed}
                closeMenu={closeMenu}
                setListData={setListData}
                visibleList={visibleList}
                setVisibleList={setVisibleList}
                highlightedList={highlightedList}
                setHighlightedList={setHighlightedList}
                parsedWorkflows={parsedWorkflowsWithTranslations}
                conversationFooterRef={props.conversationFooterRef}
                conversationFooterRowRef={props.conversationFooterRowRef}
            />
            {/* Old approach, if you want to separately show background on the convo footer area, uncomment */}
            {/* {displayFooterPart && (<FooterPart menuIsShown={shown} footerPartShown={morphType === MorphType.None} />)} */}
        </>
    )
})