/** These morphTypes need additional height for the consent and will have the 
 * consent appear immediately without any user interaction (such as typing or 
 * clicking on a disabled feature)
**/

import { MorphType } from "./MorphType";

export const welcomeMorphTypes = [
  MorphType.CreditRepairWelcome, 
  MorphType.RefinanceCalculatorWelcome,
  MorphType.CalendarMonth,
  MorphType.DOB,
  MorphType.Time,
  MorphType.SlotMachine,
  MorphType.ShareCustomLink
];
