import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Styles from '@styles/ChatSendButton.module.css';
import { SendButton } from '@styles/ChatSendButtonStyle';
import { useFooterContext } from '@contexts/FooterContext';
import { useAppsMenuState } from './hooks/useAppsMenuState';

const sxPulseAnimationStyle = {
    "@keyframes sendBtnPulse": {
        "0%": { transform: "scale(1) rotate(0)", opacity: 1 },
        "33%": { transform: "scale(1.02) rotate(40deg)", opacity: 1 },
        "66%": { transform: "scale(1.05) rotate(-30deg)", opacity: 1 },
        "100%": { transform: "scale(1) rotate(0)", opacity: 1 },
    },
}

const searchModeSendButtonColorStyle = {
    backgroundColor: 'var(--blue850)'
}

let isAnimating = false;

const searchIconStyle = { width: '26px', height: '26px' };

const sendButtonContainerClassNameStr = [Styles.chatSendButtonContainer, Styles.maxHeight].join(' ');

const SearchIconButton = () => {
    const { appsMenuState } = useAppsMenuState()
    const footerContext = useFooterContext();
    const buttonRef = React.useRef(null);
    const tmIdRef = React.useRef(null);

    const handleClick = () => {
        // play animation only if not animating already
        if (!isAnimating && footerContext?.chatInputFieldRef?.current?.unformattedValue === '') {
            isAnimating = true;
            buttonRef.current.style.animation = "sendBtnPulse 0.4s ease-out";
            tmIdRef.current = setTimeout(() => {
                buttonRef.current.style.animation = "";
                isAnimating = false;
            }, 400);
        }
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(tmIdRef.current);
        }
    }, []);

    const additionalIconStyles = appsMenuState.searchMode || appsMenuState.searchView ? { color: "white" } : {};
    const additionalSendButtonContainerStyles = appsMenuState.searchMode || appsMenuState.searchView ? searchModeSendButtonColorStyle : {};

    return (
        <div
            className={sendButtonContainerClassNameStr}
            onClick={handleClick}
            ref={buttonRef}
        >
            <SendButton
                sx={sxPulseAnimationStyle}
                className={Styles.sendButton}
            >
                <div className={Styles.sendArrowContainer} style={additionalSendButtonContainerStyles}>
                    <SearchIcon className={Styles.arrow} style={{...searchIconStyle, ...additionalIconStyles}} />
                </div>
            </SendButton>
        </div>
    );
}

export default SearchIconButton;