import React from 'react';
import { isMobile } from "@common/deviceTypeHelper";
import { Grow, ButtonBase, CircularProgress } from "@mui/material";
import { TOGGLE_ANIMATION_DURATION } from "../AppsMenu";
import useTap from "../hooks/useTap";
import SingleListItem from './SingleListItem';

const Styles = {
    BaseSingleItemButton: (isMobile, isHighlighted) => ({
        // backgroundColor: "#1d315e", // Example background color
        borderRadius: "8px", // Unified border-radius
        display: "flex", // Flex layout maintained
        flexDirection: "column", // For vertical stacking inside the item
        justifyContent: "center", // Center content vertically
        alignItems: "center", // Center content horizontally
        transition: "all 0.5s ease", // Smooth transitions
        padding: "8px", // Padding for inner spacing
        overflow: "hidden", // Ensures ripple effect stays inside
        width: '100%',
        height: '100%',
        ...((isHighlighted !== undefined && isHighlighted === false) ? { opacity: '0.3' } : {}),
        "&:hover": {
            // backgroundColor: "#1d315e", // Subtle hover background effect
            // only on desktop
            ...(isMobile === false ? { transform: "scale(1.05) translateY(-3px);" } : {})
        },
    }) as React.CSSProperties
}

// const USER_LEFT_CELL_HIDE_INFO_DELAY_TIMEOUT = 2000; // 2 seconds

const SingleListItemWrapper: React.FC<any> = React.memo((props) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const { onTouchStart, onTouchEnd } = useTap(props.onClickTouchHandler);

    // TODO UNCOMMENT when info section is reintroduced
    // const tmsRef = React.useRef([]);
    // const userLeftCellDelay = React.useRef(null);

    // const onHover = () => {
    //     // remove the on going timeout to hide the info section if user came back (by hovering again)
    //     clearTimeout(userLeftCellDelay.current);
    //     tmsRef.current.push(
    //         setTimeout(
    //             () => setIsHovered((prev) => true),
    //             1500
    //         )
    //     );
    // }

    // const onLoseHover = () => {
    //     setTimeout(
    //         () => {
    //             setIsHovered((prev) => false);
    //         },
    //         0
    //     );

    //     // set the timeout to hide the info section in some time after user moves the mouse away from the component.
    //     userLeftCellDelay.current = setTimeout(() => {
    //         props.hideInfo();
    //     }, USER_LEFT_CELL_HIDE_INFO_DELAY_TIMEOUT);

    //     tmsRef.current.forEach((tm) => clearTimeout(tm));
    // }

    const isHighlighted = props.highlightedList.length === 0 ? undefined : props.highlightedList[props.index];
    const isLoading = props.optionsListState?.[props.index]?.isLoading;
    return (
        <Grow in={props.visibleList[props.index]} key={props.index} timeout={TOGGLE_ANIMATION_DURATION}>
            <div
                // TODO uncomment in the future once the info section design is done.
                // onMouseEnter={onHover}
                // onMouseLeave={onLoseHover}
                style={props.flexRelatedStyles}
                {...(isMobile()
                    ? {
                        onTouchEnd: onTouchEnd,
                        onTouchStart: onTouchStart,
                    }
                    : { onClick: props.onClickTouchHandler }
                )}
            >
                <ButtonBase
                    focusRipple
                    sx={Styles.BaseSingleItemButton(isMobile(), isHighlighted)}
                >
                    {isLoading === true
                        ? <CircularProgress />
                        : <SingleListItem
                            item={props.item}
                            index={props.index}
                            isHovered={isHovered}
                            currLang={props.currLang}
                            hideInfo={props.hideInfo}
                            optionsListState={props.optionsListState}
                            setOptionsListState={props.setOptionsListState}
                            onSingleItemInfoClickHandler={props.infoClickHandler}
                            listItemStateData={props.optionsListState[props.index]}
                        />
                    }
                </ButtonBase>
            </div>
        </Grow>
    );
});

export default SingleListItemWrapper;