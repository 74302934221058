import { IErrandContext } from '@contexts/ErrandContext';
import axiosCall from '../Services/axios';
import LanguageUtils from './LanguageUtils';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { IUserData } from '@interfaces/Conversation';
import { MorphType } from './MorphType';

// Language Map
const localLanguageMap = {
  en: {
    en: 'English',
    es: 'Spanish',
    ko: 'Korean',
    vi: 'Vietnamese',
    fr: 'French',
    ar: 'Arabic',
    de: 'German',
    ru: 'Russian',
    tl: 'Tagalog',
    zh: 'Chinese',
    pt: 'Portuguese',
    ht: 'Haitian Creole',
    pl: 'Polish',
    hi: 'Hindi',
    ja: 'Japanese',
  },
  es: {
    en: 'Inglés',
    es: 'Español',
    ko: 'Coreano',
    vi: 'Vietnamita',
    fr: 'Francés',
    ar: 'Árabe',
    de: 'Alemán',
    ru: 'Ruso',
    tl: 'Tagalo',
    zh: 'Chino',
    pt: 'Portugués',
    ht: 'Criollo Haitiano',
    pl: 'Polaco',
    hi: 'Hindi',
    ja: 'Japonés',
  },
  ko: {
    en: '영어',
    es: '스페인어',
    ko: '한국어',
    vi: '베트남어',
    fr: '프랑스어',
    ar: '아랍어',
    de: '독일어',
    ru: '러시아어',
    tl: '타갈로그어',
    zh: '중국어',
    pt: '포르투갈어',
    ht: '아이티 크레올어',
    pl: '폴란드어',
    hi: '힌디어',
    ja: '일본어',
  },
  vi: {
    en: 'Tiếng Anh',
    es: 'Tiếng Tây Ban Nha',
    ko: 'Tiếng Hàn',
    vi: 'Tiếng Việt',
    fr: 'Tiếng Pháp',
    ar: 'Tiếng Ả Rập',
    de: 'Tiếng Đức',
    ru: 'Tiếng Nga',
    tl: 'Tiếng Tagalog',
    zh: 'Tiếng Trung',
    pt: 'Tiếng Bồ Đào Nha',
    ht: 'Tiếng Creole Haiti',
    pl: 'Tiếng Ba Lan',
    hi: 'Tiếng Hindi',
    ja: 'Tiếng Nhật',
  },
  fr: {
    en: 'Anglais',
    es: 'Espagnol',
    ko: 'Coréen',
    vi: 'Vietnamien',
    fr: 'Français',
    ar: 'Arabe',
    de: 'Allemand',
    ru: 'Russe',
    tl: 'Tagalog',
    zh: 'Chinois',
    pt: 'Portugais',
    ht: 'Créole haïtien',
    pl: 'Polonais',
    hi: 'Hindi',
    ja: 'Japonais',
  },
  ar: {
    en: 'الإنجليزية',
    es: 'الإسبانية',
    ko: 'الكورية',
    vi: 'الفيتنامية',
    fr: 'الفرنسية',
    ar: 'العربية',
    de: 'الألمانية',
    ru: 'الروسية',
    tl: 'التاغالوغية',
    zh: 'الصينية',
    pt: 'البرتغالية',
    ht: 'الكريول الهايتي',
    pl: 'البولندية',
    hi: 'الهندية',
    ja: 'اليابانية',
  },
  de: {
    en: 'Englisch',
    es: 'Spanisch',
    ko: 'Koreanisch',
    vi: 'Vietnamesisch',
    fr: 'Französisch',
    ar: 'Arabisch',
    de: 'Deutsch',
    ru: 'Russisch',
    tl: 'Tagalog',
    zh: 'Chinesisch',
    pt: 'Portugiesisch',
    ht: 'Haitianisches Kreolisch',
    pl: 'Polnisch',
    hi: 'Hindi',
    ja: 'Japanisch',
  },
  ru: {
    en: 'Английский',
    es: 'Испанский',
    ko: 'Корейский',
    vi: 'Вьетнамский',
    fr: 'Французский',
    ar: 'Арабский',
    de: 'Немецкий',
    ru: 'Русский',
    tl: 'Тагальский',
    zh: 'Китайский',
    pt: 'Португальский',
    ht: 'Гаитянский креольский',
    pl: 'Польский',
    hi: 'Хинди',
    ja: 'Японский',
  },
  tl: {
    en: 'Ingles',
    es: 'Espanyol',
    ko: 'Koreano',
    vi: 'Biyetnames',
    fr: 'Pranses',
    ar: 'Arabo',
    de: 'Aleman',
    ru: 'Ruso',
    tl: 'Tagalog',
    zh: 'Intsik',
    pt: 'Portuges',
    ht: 'Kreyol Ayisyen',
    pl: 'Polako',
    hi: 'Hindi',
    ja: 'Hapon',
  },
  zh: {
    en: '英语',
    es: '西班牙语',
    ko: '韩语',
    vi: '越南语',
    fr: '法语',
    ar: '阿拉伯语',
    de: '德语',
    ru: '俄语',
    tl: '他加禄语',
    zh: '中文',
    pt: '葡萄牙语',
    ht: '海地克里奥尔语',
    pl: '波兰语',
    hi: '印地语',
    ja: '日语',
  },
  pt: {
    en: 'Inglês',
    es: 'Espanhol',
    ko: 'Coreano',
    vi: 'Vietnamita',
    fr: 'Francês',
    ar: 'Árabe',
    de: 'Alemão',
    ru: 'Russo',
    tl: 'Tagalo',
    zh: 'Chinês',
    pt: 'Português',
    ht: 'Crioulo Haitiano',
    pl: 'Polonês',
    hi: 'Hindi',
    ja: 'Japonês',
  },
  ht: {
    en: 'Angle',
    es: 'Panyòl',
    ko: 'Koreyen',
    vi: 'Vyètnamyen',
    fr: 'Franse',
    ar: 'Arab',
    de: 'Alman',
    ru: 'Ris',
    tl: 'Tagalog',
    zh: 'Chinwa',
    pt: 'Pòtigè',
    ht: 'Kreyòl Ayisyen',
    pl: 'Polonè',
    hi: 'Hindi',
    ja: 'Japonè',
  },
  pl: {
    en: 'Angielski',
    es: 'Hiszpański',
    ko: 'Koreański',
    vi: 'Wietnamski',
    fr: 'Francuski',
    ar: 'Arabski',
    de: 'Niemiecki',
    ru: 'Rosyjski',
    tl: 'Tagalski',
    zh: 'Chiński',
    pt: 'Portugalski',
    ht: 'Haitański Kreolski',
    pl: 'Polski',
    hi: 'Hinduski',
    ja: 'Japoński',
  },
  hi: {
    en: 'अंग्रेज़ी',
    es: 'स्पेनिश',
    ko: 'कोरियाई',
    vi: 'वियतनामी',
    fr: 'फ़्रांसीसी',
    ar: 'अरबी',
    de: 'जर्मन',
    ru: 'रूसी',
    tl: 'तागालोग',
    zh: 'चीनी',
    pt: 'पुर्तगाली',
    ht: 'हैतियन क्रियोल',
    pl: 'पोलिश',
    hi: 'हिन्दी',
    ja: 'जापानी',
  },
  ja: {
    en: '英語',
    es: 'スペイン語',
    ko: '韓国語',
    vi: 'ベトナム語',
    fr: 'フランス語',
    ar: 'アラビア語',
    de: 'ドイツ語',
    ru: 'ロシア語',
    tl: 'タガログ語',
    zh: '中国語',
    pt: 'ポルトガル語',
    ht: 'ハイチ語',
    pl: 'ポーランド語',
    hi: 'ヒンディー語',
    ja: '日本語',
  },
};

/**
 * User active chat type
 */
const userActiveChatType = [
  "conversation",
  "errand",
  "form",
  "page",
  "activity",
  "conditions"
];

/**
 * User active chat status
 */
const userActiveChatStatus = [
  "unattended",
  "active",
  "inactive",
  "waiting-updates"
];

/**
 * This is allowed fields that can be updated in translateMessageToLocalLang()
 */
const allowedToUpdateFields = {
  detectedLanguage: null,
  translatedLanguage: null,
  translatedMessage: null,
};

/**
 * flag for translation endpoint, in normal condition this flag is always true
 */
let translationEndpointGood = true;

/**
 * We will show this messages on UI once translation endpoint fails
 */
const defaultTranslateMessage = {
  'es': 'Actualmente estamos experimentando interrupciones con los servicios de traducción. Continuando en ingles',
  'ko': '현재 번역 서비스가 중단되고 있습니다. 계속해서 영어로',
  'en': 'We are currently experiencing interruptions with translation services. Continuing on in English',
  'vi': 'Hiện tại, chúng tôi đang gặp sự cố với dịch vụ dịch thuật. Chúng tôi sẽ tiếp tục bằng tiếng Anh',
  'fr': 'Nous rencontrons actuellement des interruptions avec les services de traduction. Nous continuerons en anglais',
  'ar': 'نواجه حاليا انقطاعات مع خدمات الترجمة. سنستمر باللغة الإنجليزية',
  'de': 'Wir erleben derzeit Unterbrechungen bei den Übersetzungsdiensten. Wir fahren auf Englisch fort',
  'ru': 'B настоящее время мы испытываем перебои co службами перевода. Мы продолжим на английском',
  'tl': 'Nakakaranas kami ngayon ng mga pagkaantala sa mga serbisyo ng pagsasalin. Magpapatuloy kami sa Ingles',
  'zh': '我们目前正在经历翻译服务的中断。我们将继续使用英语',
  'pt': 'Atualmente estamos enfrentando interrupções com os serviços de tradução. Continuaremos em inglês',
  'ht': 'Nou kounye a ap fè eksperyans entèripsyon ak sèvis tradiksyon yo. N ap kontinye an Anglè',
  'pl': 'Obecnie doświadczamy przerw w usługach tłumaczeniowych. Będziemy kontynuować po angielsku',
  'hi': 'हम वर्तमान में अनुवाद सेवाओं में व्यवधान का अनुभव कर रहे हैं। हम अंग्रेजी में जारी रखेंगे',
  'ja': '現在、翻訳サービスに中断が発生しています。 英語で続けます',
}

/**
 * check if input is string or not
 */
const validateString = (search) => {
  if (typeof search === 'string' || search instanceof String) {
    return true;
  } else {
    return false;
  }
};

/**
 * Private function for converting a language code into a full language name.
 * @param {String} code The code being converted.
 * @returns The language name which corresponds to the given code.
 */
const getLangName = (code) => {
  const localizedLanguage = LanguageUtils.fetchLocalizationLanguageSetting();

  if (localLanguageMap[localizedLanguage] && localLanguageMap[localizedLanguage][code]) {
    return localLanguageMap[localizedLanguage][code];
  }
  return code;
};

// this function translates input string to local language
const translateToLocalLang = async (inputString, source = null) => {
  // make sure input is string
  if (typeof inputString === 'string' || inputString instanceof String) {
    // fetch local language
    let targetLang = LanguageUtils.fetchLocalizationLanguageSetting();

    // if it's undefined somehow, set default value
    if (targetLang === undefined || targetLang === null) targetLang = 'en';

    return axiosCall({
      url: 'language/detect/translate',
      method: 'POST',
      data: {
        data: [{ message: inputString, source }],
        targetLang,
      },
    })
      .then((data) => {
        translationEndpointGood = true;
        return {
          detectedLanguage: getLangName(data[0].detectedLanguage),
          translatedMessage: data[0].translatedMessage,
          fail: false,
        };
      })
      .catch((err) => {
        translationEndpointGood = false;
        console.error('language/detect/translate API failed somehow!');
        return { detectedLanguage: null, translatedMessage: defaultTranslateMessage[targetLang], fail: true };
      });
  } else {
    console.info('translateToLocalLang input is not string!');
    return { detectedLanguage: null, translatedMessage: '', fail: false };
  }
};

/**
 * This function make sure that local language version of message exists in input message
 * 1. it searches local langauge in message
 * 2. if local langauge exists in message ---> do nothing just return
 * 3. if local langauge doesn't exist in message ---> request message/translate endpoint
 * 4. this endpoint may update fields defined in allowedToUpdateFields
 * 5. if language translation happens, Database entry updated as well
 * @param {*} message
 * @returns
 */
const translateMessageToLocalLang = async (message: IMessage) => {
  // validate message
  if (!message || !message._id) {
    console.warn('common.translateMessageToLocalLang message is undefined');
    return;
  }

  // fetch local language
  let targetLang = LanguageUtils.fetchLocalizationLanguageSetting();

  // if it's undefined somehow, set default value
  if (!targetLang) targetLang = 'en';

  if (message.detectedLanguage === targetLang || message.translatedLanguage === targetLang) {
    // targetLang already exists in current message, noneed to modify message
    return;
  }

  // targetLang doesn't exists in current message
  // do translation or detect language in Morgan-Core
  // if any action happened from above line, updated entry in the DB
  // fetch updated data through following endpoint call

  // following line is updated input paramter message from db
  await axiosCall({
    url: 'message/translate',
    method: 'PUT',
    data: {
      _id: message._id,
      targetLang,
    },
  }).then((data) => {
    translationEndpointGood = true;
    // if necessary update fields
    for (let key in allowedToUpdateFields) {
      if (data[key] !== undefined) {
        message[key] = data[key];
      }
    }
  }).catch((err) => {
    translationEndpointGood = false;
    console.error(`message/translate API failed somehow! ${err.message}`);
  });
};

const EMOJIS_OR_WHITESPACE_REGEX = /^[\p{RGI_Emoji}\s]+$/v;
const messageIsEmojisOrWhitespace = (message: string): boolean => EMOJIS_OR_WHITESPACE_REGEX.test(message.trim());

/**
 * depending on local language this function decides that UI translation feature works or not
 *
 * @param {*} message Message
 * @returns
 */
const uiTranslationController = (message): {dispMessage: string, isTranslated: boolean} => {
  // validate message and verify required keys are present
  if (typeof message !== 'object' || message === null || !message.sender) {
    console.log('common.uiTranslationController message is undefined');
    return { dispMessage: '', isTranslated: false };
  }

  // fetch local language
  let targetLang = LanguageUtils.fetchLocalizationLanguageSetting();

  // own message, noneed to translate
  if (message.sentByCurrentUser) {
    // Determine if the message needs to show as translated, excluding messages that are only emojis/whitespace.
    const isTranslated = message.detectedLanguage !== targetLang && !messageIsEmojisOrWhitespace(message.message);
    return {
      // If translation is needed, use the translated message; otherwise, use the original message.
      dispMessage: isTranslated ? message.translatedMessage : message.message, 
      // Indicate whether the message has been translated.
      isTranslated 
    };
  }

  // if it's undefined somehow, set default value
  if (targetLang === undefined || targetLang === null) targetLang = 'en';

  // original message language matches targetLang, show message as is
  if (message.detectedLanguage === targetLang) {
    return { dispMessage: message.message, isTranslated: false };
  }

  // translated message language matches targetLang, show translated message
  if (message.translatedLanguage === targetLang) {
    return { dispMessage: message.translatedMessage, isTranslated: true };
  }

  if (translationEndpointGood) {
    // Following line ran when own chat detected language is different than set language
    return { dispMessage: message.message, isTranslated: false };
  } else {
    // if translation endpoint failed, then show default translation message
    return { dispMessage: defaultTranslateMessage[targetLang], isTranslated: true };
  }
};

/**
 * Update 3 subfields of object to reset footer's active userAction
 * 
 * @param data
 * @param placeholder
 */
const ResetFooterUserAction = (data: IErrand, placeholder?: string) => {
  data.action = null;
  data.icon = '';

  if (placeholder) {
    data.placeholder = placeholder;
  } else {
    // reset
    data.placeholder = 'Type here...';
  }
};

/**
 * Filter out only valid user data fields
 * 
 * @param newData data came through socket event
 * @returns 
 */
const ValidUserDataFields = (newData: any) => {
  const firstname = newData?.firstname;
  const middlename = newData?.middlename;
  const lastname = newData?.lastname;
  const nickname = newData?.nickname;

  const updatedFields: IUserData = {};

  if (firstname) updatedFields.firstname = firstname;
  if (middlename) updatedFields.middlename = middlename;
  if (lastname) updatedFields.lastname = lastname;
  if (nickname) updatedFields.nickname = nickname;

  return updatedFields;
}

/**
 * get epoch time (number) based on createdAt field
 * 
 * @param obj Object which must have createdAt field
 * @returns 
 */
const GetEpochFromObjCreatedAt = (obj: any): number => {
  try {
    const createdAt = obj.createdAt;

    if (typeof createdAt !== 'string' || createdAt.length !== 24) {
      throw new Error('Invalid createdAt field!');
    }

    // Convert ISO 8601 string to a Date object
    const dateObject = new Date(createdAt);

    // Get the time in milliseconds since the Unix epoch
    const epochTime = dateObject.getTime();

    return epochTime;
  } catch (err) {
    console.error('common.ts: GetEpochFromObjCreatedAt', err.message);
    return -1;
  }
}

/**
 * Get new Message index on existing messages array
 * @note Here we have assumption that messages array is already sorted by createdAt timestamp
 */
const GetMessageIndexOnMessagesArray = (arr: Array<IMessage>, newMessage: IMessage) => {
  let index = arr.length;

  // message array is already sorted by createdAt timestamp
  for (let i = arr.length - 1; i >= 0; i--) {
    if (GetEpochFromObjCreatedAt(arr[i]) > GetEpochFromObjCreatedAt(newMessage)) {
      index = i;
    } else {
      break;
    }
  }

  return index;
}

/**
 * Focuses the userAction associated with `message` in the conversation footer
 */
const focusUserAction = async (errandContext: IErrandContext, rootContext: any, message: IMessage) => {
  // Reset the morph data if needed
  if ([MorphType.Attachment, MorphType.PhotoMain, MorphType.PhotoPlain].includes(errandContext.morphType)) {
    errandContext.setMainPhoto(null);
    errandContext.setPhotoLimit(null);
    errandContext.setPhotoSelectorIndex(null);
  }
  errandContext.setEditMessageId('');
  errandContext.setMessageOptionsIndex(-1);
  errandContext.setIsMorphedFooterCloseButtonOnLeft(false);

  // Update the state of the userAction
  const payload = {
    url: `useraction/${message?.userAction?._id}`,
    method: 'put',
    data: {
      status: 'in-progress',
    },
  };
  await axiosCall(payload);

  // Update the icon, placeholder, and action in the conversation footer
  rootContext.setErrands((prev) => {
    if (!Array.isArray(prev)) {
      console.warn('setErrands prev is not an array');
      prev = [];
    }
    let index = prev.findIndex((e) => e._id === errandContext.errandId);
    prev[index] = {
      ...prev[index],
      icon: message.icon,
      placeholder: message?.action?.description,
      action: {
        ...message?.userAction,
        action: message?.action,
        userActionId: message?.userAction?._id,
        active: true,
      },
      recipients: message.intendedAudience ? [message.sender._id, ...message.intendedAudience].sort() : [],
    };
    return [...prev];
  });
  // Focus the input bar
  errandContext?.footerInputRef?.current?.focus();
}

const ParticipantNameGet = (userData: IUserData): string => {
  const { firstname, lastname, nickname } = userData || {};

  let result;

  // Return the nickname if it's valid and non-empty
  if (validateString(nickname) && nickname.trim().length > 0) {
    result = nickname.trim().toLowerCase();
  } else {
    // Construct the full name using firstname and lastname, converting to lowercase
    result = `${firstname?.trim()?.toLowerCase() || ''} ${lastname?.trim()?.toLowerCase() || ''}`.trim();
  }

  if (result === 'angelai') {
    return 'AngelAi';
  }

  return result.charAt(0).toUpperCase() + result.slice(1);
}

export {
  translateToLocalLang,
  getLangName,
  validateString,
  translateMessageToLocalLang,
  uiTranslationController,
  userActiveChatType,
  userActiveChatStatus,
  ResetFooterUserAction,
  ValidUserDataFields,
  GetEpochFromObjCreatedAt,
  GetMessageIndexOnMessagesArray,
  focusUserAction,
  ParticipantNameGet,
};
