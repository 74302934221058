import { useState, useCallback, useRef, useEffect, useMemo } from 'react';

import { filterByStatus, getUserStatus, parseFetchedWorkflowsData } from '@components/MorphUserPromptsMenu/utils';
import { TWorkflowData_UPmenu, TWorkflowDataFetched_UPmenu } from '@components/MorphUserPromptsMenu/types';
import { useTranslation } from 'react-i18next';
import axiosCall from '@services/axios';

const delay = (ms: number, cancelToken: { cancelled: boolean; cleanup?: () => void }): Promise<void> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      if (cancelToken.cancelled) {
        reject(new Error('Animation cancelled'));
      } else {
        resolve();
      }
    }, ms);

    // Clear timeout if animation is cancelled
    cancelToken.cleanup = () => clearTimeout(timeout);
  });
};

// Workflow API custom hook.
const DEFAULT_WORKFLOW_FETCH_URL = `workflow?fields=_id,displayName,description,name&active=true&visibility.user=true`;
const WORKFLOW_FETCH_WORFKLOW_ICONS_URL = `workflow?fields=workflowIcon&active=true&visibility.user=true`;
const useWorkflowApi = (
  userContext: any,
  url: string = DEFAULT_WORKFLOW_FETCH_URL,
  icons_url: string = WORKFLOW_FETCH_WORFKLOW_ICONS_URL
) => {
  const { i18n } = useTranslation();
  const [fetchedWorkflowsData, setFetchedWorkflowsData] = useState<TWorkflowDataFetched_UPmenu[] | null>([]);
  const [parsedWorkflowsData, setParsedWorkflowsData] = useState<TWorkflowData_UPmenu[] | null>([]);
  const abortControllerRef = useRef<AbortController | null>(null);

  const sortByName = (wfDataArr: TWorkflowData_UPmenu[]) => {
    return wfDataArr.sort((a, b) => {
      const nameA: string = a.name[i18n.language] ?? '';
      const nameB: string = b.name[i18n.language] ?? '';
      return nameA.localeCompare(nameB);
    });
  };

  const fetch = useCallback(async (): Promise<TWorkflowDataFetched_UPmenu[]> => {
    try {
      const abortController = abortControllerRef.current || new AbortController();
      abortControllerRef.current = abortController;

      const workflows = await axiosCall(
        {
          url,
        },
        { signal: abortController.signal }
      );
      return workflows;
    } catch (e) {
      console.error('Error fetching workflows:', e);
      throw e;
    }
  }, [url]);

  const fetchIcons = useCallback(async (): Promise<{ _id: string; workflowIcon: string }[]> => {
    try {
      const abortController = abortControllerRef.current || new AbortController();
      abortControllerRef.current = abortController;

      const icons = await axiosCall({ url: icons_url }, { signal: abortController.signal });
      return icons;
    } catch (e) {
      console.error('Error fetching icons:', e);
      throw e;
    }
  }, [icons_url]);

  const parse = useCallback(
    (fetchedData: TWorkflowDataFetched_UPmenu[]): TWorkflowData_UPmenu[] => {
      const wfDataArr = parseFetchedWorkflowsData(fetchedData);
      const userStatus = getUserStatus(userContext);
      const wfDataArrSorted = sortByName(wfDataArr);
      return filterByStatus(userStatus, wfDataArrSorted);
    },
    [userContext]
  );

  const refetch = useCallback(
    async (errCb: (err: any) => void): Promise<void> => {
      const workflowsPromise = fetch().then((fetchedData) => {
        setFetchedWorkflowsData(fetchedData);
        const parsedData = parse(fetchedData);
        setParsedWorkflowsData(parsedData);
        return parsedData; // Pass parsedData forward
      });

      const iconsPromise = fetchIcons();

      Promise.allSettled([workflowsPromise, iconsPromise])
        .then(([workflowsResult, iconsResult]) => {
          if (workflowsResult.status === 'fulfilled' && iconsResult.status === 'fulfilled') {
            const parsedData = workflowsResult.value;
            const fetchedIcons = iconsResult.value;

            const updatedData = parsedData.map((item) => ({
              ...item,
              workflowIcon: fetchedIcons.find((icon) => icon._id === item._id)?.workflowIcon || null,
            }));

            setParsedWorkflowsData(updatedData);
          }
        })
        .catch(errCb);
    },
    [fetchIcons, parse]
  );

  const get = useCallback(() => parsedWorkflowsData, [parsedWorkflowsData]);

  return {
    fetch,
    refetch,
    get,
    fetchedWorkflowsData,
    parsedWorkflowsData,
    abort: () => abortControllerRef.current?.abort(),
  };
};

export { useWorkflowApi, delay };
