import { useEffect, useRef } from "react";

let initialHeightBuffer;
let wasDraggedDown = false;
const DEFAULT_THRESHOLD = 80;

function useDragDown(ref, onDragDownHandler, moveHandler, currHeight, threshold = DEFAULT_THRESHOLD) {
    const startYRef = useRef(null); // Store initial Y position

    useEffect(() => {
        const element = ref.current;
        if (!element) return;

        const handleStart = (e) => {
            const startY = e.touches?.[0]?.clientY ?? e.clientY;
            startYRef.current = startY;
            document.body.style.overflow = 'hidden';
            initialHeightBuffer = currHeight;
        };

        const handleMove = (e) => {
            if (startYRef.current === null) return;

            const currentY = e.touches?.[0]?.clientY ?? e.clientY;
            const deltaY = currentY - startYRef.current;

            // means the drag goes downwards
            if (deltaY > 0) {
                moveHandler(initialHeightBuffer, deltaY);
            }

            if (deltaY > threshold) {
                wasDraggedDown = true;
                onDragDownHandler();
                startYRef.current = null; // Reset to prevent multiple triggers
            }
        };

        const handleEnd = () => {
            startYRef.current = null;
            document.body.style.overflow = '';
            // if was not dragged down, move back to init position.
            if(!wasDraggedDown) moveHandler(initialHeightBuffer, 0);

            wasDraggedDown = false;
        };

        // Attach event listeners
        element.addEventListener("touchstart", handleStart);
        element.addEventListener("touchmove", handleMove);
        element.addEventListener("touchend", handleEnd);

        // Cleanup event listeners
        return () => {
            element.removeEventListener("touchstart", handleStart);
            element.removeEventListener("touchmove", handleMove);
            element.removeEventListener("touchend", handleEnd);
        };
    }, [ref, onDragDownHandler, moveHandler, threshold]);
}

export default useDragDown;
