import eventBus from "@common/eventBus";
import { useEffect } from "react";

const useEventBusListener = (eventChannel, callback, deps: any[]) => {
    useEffect(() => {
        if (!eventBus || !eventChannel || !callback) return;

        eventBus.on(eventChannel, callback);

        return () => {
            eventBus.remove(eventChannel, callback);
        };
    }, [eventChannel, callback, ...deps]);
};

export default useEventBusListener;
