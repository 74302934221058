import { getTimezone } from '@storage/userStorage';

// These are the timezones used during daylight saving time
const isDST = ['ADT', 'EDT', 'CDT', 'MDT', 'PDT', 'AKDT', 'HADT'];

// These are the timezones for non-daylight saving time
const notDST = ['AST', 'EST', 'CST', 'MST', 'PST', 'AKST', 'HAST', 'HST'];

// These are the timezones that do not ever observe daylight saving time
// Update: This timezone is not used for workshop registration
// const neverDST = ['ChST'] 

// This checks to see if we are currently observing daylight saving time so we 
// can use the most relevant array of timezones
const currentTimezones = () => {
  const date = new Date();
  const currentOffset = date.getTimezoneOffset();
  date.setMonth(0);
  date.setDate(1);
  const standardOffset = date.getTimezoneOffset();
  const isDSTNow = currentOffset !== standardOffset;

  let currentTimezones: Array<string>;
  if (isDSTNow){
    currentTimezones = [...isDST];
  }else{
    currentTimezones = [...notDST];
  }
  return currentTimezones
}

// Returns time zone abbreviation like PST / AST / EST
const getTimezoneAbbrev = () => {
  try {
    const date = new Date();
    const timezone = getTimezone().toString()
    const options = { timeZoneName: 'short' as 'short', timeZone: timezone };
    
    const formatter = new Intl.DateTimeFormat('en', options);
    const timeZoneAbbreviation = formatter.formatToParts(date)
      ?.find(part => part.type === 'timeZoneName')?.value;
    return timeZoneAbbreviation
  } catch (error) {
    console.error(`getTimezoneAbbrev.getTimezoneAbbrev encountered error`, error);
    return '';
  }
}

const getTimezoneIndex = (timezones) => {
  if (!timezones) return 4;
  let index = timezones.indexOf(getTimezoneAbbrev())
  if (index === -1){
    index = 4 // set default timezone to PST/PDT if user is outside US
  }
  return index
}

export { currentTimezones, getTimezoneAbbrev, getTimezoneIndex }
