import React, { ChangeEvent } from 'react';
import { ValidatorFunctions } from '@common/Validators';

enum ActionNameType {
  avatarVoice = 'avatarVoice',
  influencerPhotograph = 'influencerPhotograph',
}

const acceptedAudioTypes = new Set(['audio/mpeg', 'audio/mp3']);

const acceptedImageTypes = new Set(['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'image/bmp']);

const acceptedVideoTypes = new Set([
  'video/mp4', // MP4
  'video/webm', // WebM
  'video/avi', // AVI (not always widely supported in browsers)
  'video/mpeg', // MPEG
  'video/quicktime', // MOV (QuickTime)
]);

const typeValidateFile = (file: File): boolean => {
  const accept = [
    'image',
    'pdf',
    'text/plain',
    'msword',
    'excel',
    'powerpoint',
    'officedocument',
    'opendocument',
    'xml',
    'fnm',
    'csv',
    'm4a',
    'wav',
    'mp3',
    'aac',
    'flac',
    'wma',
    'aiff',
    'mpeg',
  ];

  const acceptNoType = ['fnm'];

  const fileType = file?.type;
  const fileName = file?.name;

  if (fileType && accept.some((x) => fileType.includes(x))) {
    return true;
  } else {
    // If file doesn't have a type, check file ending
    if (fileName && fileType === '') {
      const fileParts = fileName.split('.');

      const lastPart = fileParts[fileParts.length - 1];

      const ending = lastPart.toLowerCase();

      if (acceptNoType.includes(ending)) {
        return true;
      }
    }
    return false;
  }
};

const mediaTypeValidateFile = (file: File): boolean => {
  const accept = ['image', 'mp4', 'webm', 'avi', 'mpeg', 'quicktime'];

  const acceptNoType = ['fnm'];

  const fileType = file?.type;
  const fileName = file?.name;

  if (fileType && accept.some((x) => fileType.includes(x))) {
    return true;
  } else {
    // If file doesn't have a type, check file ending
    if (fileName && fileType === '') {
      const fileParts = fileName.split('.');

      const lastPart = fileParts[fileParts.length - 1];

      const ending = lastPart.toLowerCase();

      if (acceptNoType.includes(ending)) {
        return true;
      }
    }
    return false;
  }
};

const isValidMP3 = (fileType: string, fileName: string): boolean => {
  if (!ValidatorFunctions.isTypeOfString(fileName)) {
    return false;
  }

  if (!ValidatorFunctions.isTypeOfString(fileType)) {
    return false;
  }

  // Check MIME type first
  if (acceptedAudioTypes.has(fileType)) {
    return true;
  }

  // Fallback: Check file extension (case-insensitive)
  const fileNameLowerCase = fileName.toLowerCase();
  if (fileNameLowerCase.endsWith('.mp3')) {
    return true;
  }

  return false;
};

const ValidateFileBasedOnActionName = (file: File, actionName: ActionNameType): string => {
  const fileType = file?.type;
  const fileSize = file?.size;
  const fileName = file?.name;

  if (actionName === ActionNameType.influencerPhotograph) {
    if (
      (fileType.includes('image') || fileType.includes('video')) &&
      !acceptedImageTypes.has(fileType) &&
      !acceptedVideoTypes.has(fileType)
    ) {
      return 'documentMediaError';
    } else if (mediaTypeValidateFile(file)) {
      if (fileSize < Number(process.env.REACT_APP_MEDIA_SIZE_LIMIT)) {
        // happy path
        return '';
      } else {
        return 'mediaSizeLimit';
      }
    } else {
      return 'documentTypeError';
    }
  } else if (actionName === ActionNameType.avatarVoice) {
    if (isValidMP3(fileType, fileName)) {
      if (fileSize < Number(process.env.REACT_APP_MEDIA_SIZE_LIMIT)) {
        // happy path
        return '';
      } else {
        return 'voiceDocumentSizeError';
      }
    } else {
      return 'expected file type: mp3';
    }
  } else {
    if (typeValidateFile(file)) {
      if (fileSize < Number(process.env.REACT_APP_FILE_SIZE_LIMIT)) {
        // happy path
        return '';
      } else {
        return 'documentSizeLimit';
      }
    } else {
      return 'documentTypeError';
    }
  }
};

const handleFile = (event: ClipboardEvent | DragEvent | ChangeEvent, actionName: ActionNameType) => {
  const files: File[] = [];
  let error: string = '';

  if (event instanceof ClipboardEvent) {
    const eventClipboardData = event?.clipboardData;
    const eventClipboardDataItems = eventClipboardData?.items;

    if (eventClipboardData?.getData('text/plain')) return [];

    if (eventClipboardDataItems) {
      for (const item of eventClipboardDataItems) {
        const file = item.getAsFile();

        error = ValidateFileBasedOnActionName(file, actionName);

        if (error) {
          break;
        } else {
          files.push(file);
        }
      }
    }
  } else if (event instanceof DragEvent) {
    const eventDataTransfer = event?.dataTransfer;

    const eventDataTransferFiles = eventDataTransfer?.files;
    const eventDataTransferItems = eventDataTransfer?.items;

    if (eventDataTransferItems) {
      for (const item of eventDataTransferItems) {
        const file = item.getAsFile();

        error = ValidateFileBasedOnActionName(file, actionName);

        if (error) {
          break;
        } else {
          files.push(file);
        }
      }
    } else if (eventDataTransferFiles) {
      for (const file of eventDataTransferFiles) {
        error = ValidateFileBasedOnActionName(file, actionName);

        if (error) {
          break;
        } else {
          files.push(file);
        }
      }
    }
  } else {
    event?.preventDefault();

    const eventTarget = event?.target as HTMLInputElement;

    const eventTargetFiles = eventTarget?.files;

    if (eventTargetFiles) {
      for (const file of eventTargetFiles) {
        error = ValidateFileBasedOnActionName(file, actionName);

        if (error) {
          break;
        } else {
          files.push(file);
        }
      }
    }

    eventTarget.value = null;
  }

  if (error.length > 0) {
    return error;
  }

  files.forEach((x: File) => {
    let newName = x.name.replace(/[~` !@#$%^&*()+={}[\];:'"<>,/\\?-]/g, '');
    Object.defineProperty(x, 'name', {
      writable: true,
      value: newName,
    });
  });

  return files;
};

export { handleFile, ActionNameType };
