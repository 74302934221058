import React from 'react';
import { useFooterContext } from "@contexts/FooterContext";

// Custom hook for promptsMenuState
export const useAppsMenuState = () => {
    const { appsMenuState, setAppsMenuState } = useFooterContext();

    // Memoize the values to avoid unnecessary re-renders
    return React.useMemo(() => ({ appsMenuState, setAppsMenuState }), [
        appsMenuState.displayed,
        appsMenuState.isOpened,
        appsMenuState.listShown,
        appsMenuState.shown,
        appsMenuState.searchMode,
        appsMenuState.searchView,
        appsMenuState.searchWasCancelled,
        setAppsMenuState,
    ]);
};