import React from 'react';
import { useFooterContext } from "@contexts/FooterContext";
import { createValidator, ValidatorFunctions } from '@common/Validators';

const dimValidator = (dimVal) =>
    createValidator(dimVal, [ValidatorFunctions.isNotUndefinedNorNull, ValidatorFunctions.isTypeOfNumber]);

const isValidDimObj = (dimObj) => {
    const areDimsValid = (
        [
            dimValidator(dimObj?.width).run(),
            dimValidator(dimObj?.height).run()
        ].indexOf(false) === -1
    );

    return areDimsValid;
}

const extractDimensionsFrom = (dimObj) => {
    if (!isValidDimObj(dimObj)) {
        return [-1, -1]
    } else {
        const { width, height } = dimObj;
        return [width, height];
    }
}

export const useFooterDimensionsState = () => {
    const { footerDimensionsState } = useFooterContext();
    // Memoize the values to avoid unnecessary re-renders that can be caused by the context update
    return React.useMemo(() => ({ footerDimensionsState }), [
        ...extractDimensionsFrom(footerDimensionsState.inputContainer),
        ...extractDimensionsFrom(footerDimensionsState.conversationFooterRow),
        ...extractDimensionsFrom(footerDimensionsState.conversationFooter)
    ]);
};