/**
 * @file This file contains the definition of the `MorphType` enum. This contains enumerators for all
 * the possible morph types which might be used.
 * 
 * IMPORTANT!: If you add an additional morphType, make sure you are also accounting for how it will
 * appear if/when the returnConsent is visible. In other words, have your morphType open and then refresh
 * the page. Once you start typing or click on an errand, the returnConsent will appear and you must ensure it 
 * doesn't look bad or break anything for your new morphtype! Any buttons or features that are disabled until 
 * consent is given should be given the 'not-allowed' cursor to remain consistent with the rest of the UI. It 
 * should also trigger rootContext's handleConsentShaking.
 * 
 * If you want the returnConsent to appear immediately upon loading without having to type or click something first,
 * add it to the list in welcomeMorphTypes.ts.
 * 
 * If you don't want the returnConsent to appear at all for your morphType, make sure you update the list of 
 * "morphTypesThatHideConsent" in ConversationFooter.tsx. 
 * @author Icy Parkinson
 */

export enum MorphType {
  None,
  Attachment,
  Calendar,
  CalendarMonth,
  DOB,
  Time,
  PhotoMain,
  PhotoPlain,
  Media,
  Payment,
  Contacts,
  Errand,
  ErrandNew,
  ErrandEdit,
  FormActiveChat,
  FormInsertSignature,
  FormNavigateInitials,
  FormOpen,
  FormReadyToSend,
  Recording,
  SlotMachine,
  DownloadAppBanner,
  SongOfTheDay,
  Reply,
  MessageOptions,
  PrivateChat,
  BorrowerSelector,
  ShareCustomLink,
  Edit,
  LoanProducts,
  LoanProductPriceTable,
  UserPromptsMenu,
  VideoListMenu,
  Wallet,
  CreditRepairDisputeAccountType,
  CreditRepairWelcome,
  RefinanceCalculatorWelcome,
  UserSuggestions,
  SelectMultiple,
  NewPassword,
  AngelSign,
  LanguageSelector,
  SwipeableBoolean,
  PointsTable,
}

export enum morphIndentType {
  FormClickToClear,
  FormGenerate,
}

export const MORPH_TYPES_THAT_NEED_MODERATE_SEND_BUTTON_ADJUSTMENT = [
  MorphType.SlotMachine,
  MorphType.CreditRepairWelcome,
  MorphType.DownloadAppBanner,
  MorphType.RefinanceCalculatorWelcome,
]
export const MORPH_TYPES_THAT_NEED_SLIGHT_SEND_BUTTON_ADJUSTMENT = [
  MorphType.SelectMultiple,
  MorphType.SongOfTheDay,
]
