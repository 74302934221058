import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import React, { PropsWithChildren } from 'react';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Styles } from '../Styles/ActionControlsStyles';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { useUserContext } from '@contexts/user';
import { appsMenuButtonTools } from './AppsMenu/AppsMenuButton';

const ActionControls: React.FC<PropsWithChildren<any>> = (props) => {
  const { t } = useTranslation();
  const { morphType, errand } = useErrandContext();
  const { isOperator } = useUserContext();

  const handleClickShowPassword = () => {
    props.setShowPassword((prev) => !prev);
  };
  const handleMouseDown = (event) => {
    event.preventDefault();
    props.setJustClickedCancel(true);
  };
  
  const isAppsMenuBtnHidden = appsMenuButtonTools?.getIsHidden(morphType, errand, isOperator) ?? true;

  return (
    <Styles>
      {/* If the action is a password show the visibility icon. */}
      {props.fieldAttribute?.description === 'PASSWORD' && (
        <Tooltip title={props.showPassword ? t('hidePassword') : t('showPassword')} placement='top'>
          <IconButton
            className={'ACPasswordToggle ' + (props.isPrivate ? 'isPrivate ' : '')}
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDown}>
            {props.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Tooltip>
      )}
      {!props.isPrivate && isAppsMenuBtnHidden && (
        <Tooltip title={t('conversationFooterActionRequestCancel')} placement='top'>
          <IconButton
            className='ACCancelButton'
            onClick={() => props.cancelAction(undefined, true)}
            onMouseDown={handleMouseDown}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      )}
    </Styles>
  );
};

export default ActionControls;
