import React, { useState, useEffect } from "react";

import { useFooterDimensionsState } from "./hooks/useFooterDimensionsState";
import { isMobile, isMobileOrTablet } from "@common/deviceTypeHelper";
import useResizeObserver from "@common/hooks/useResizeObserver";
import { AppsMenuExternalState, MAIN_CONTAINER_PADDING_X_VALUE } from "./AppsMenu";
import { useErrandContext } from "@contexts/ErrandContext";
import useClickOutside from "./hooks/useClickOutside";
import OptionsList from "./MenuContent/OptionsList";
import { useTranslation } from "react-i18next";
import CssClasses from './styles.module.css'
import eventBus from "@common/eventBus";
import {
    FINAL_HEIGHT_ADDITIONAL_SPACE, CONTAINER_LEFT_POS_VALUE,
    OPTIONS_CONTAINER_PADDING_TOP_BOTTOM_VALUE,
    OPTIONS_CONTAINER_MARGIN_TOP_VALUE,
    TOP_COLLAPSE_BUTTON_PART_WIDTH,
    OPTIONS_CONTAINER_GAP_VALUE,
    BIG_SCREEN_MIN_WIDTH_VALUE,
    MAX_ITEM_HEIGHT_WIDTH,
} from "./constants";

import useThrottleImmediate from "@common/hooks/useThrottleImmediate";
import useWindowDimensions from "@common/hooks/useWindowDimensions";
import { useFooterContext } from "@contexts/FooterContext";
import useDragDown from "./hooks/useOnDragDown";
import NotFound from "./MenuContent/NotFound";

const Styles = {
    MainContainer: (shown, paddingHeight, height = null, width = null) => ({
        paddingBottom: `${paddingHeight + 3}px`,
        height: shown ? ((height && `${height}px`) ?? `fit-content`) : '100px',
        paddingLeft: isMobile() ? '6px' : '14px',
        width: width ? `${width}px` : `99%`,
        opacity: shown ? 1 : 0,
        fontFamily: 'Poppins',
        zIndex: 11
    }) as React.CSSProperties,
    TopCollapseContainerPart: (leftValue) => ({
        position: 'absolute',
        backgroundColor: 'white',
        width: `${TOP_COLLAPSE_BUTTON_PART_WIDTH}px`,
        height: '28px',
        top: '-28px',
        left: `${leftValue}px`, // center of the screen
        borderRadius: '15px 15px 0 0',
        borderTop: '0.5px solid var(--peach900)',
        borderLeft: '0.5px solid var(--peach900)',
        borderRight: '0.5px solid var(--peach900)',
        cursor: 'pointer',
        textAlign: 'center',
        padding: '8px',
        zIndex: 11
    }) as React.CSSProperties,
    topCollapseContainerText: {
        textTransform: 'capitalize',
        fontWeight: '600',
        fontSize: '21px',
        color: 'var(--peach800)',
        userSelect: 'none'
    } as React.CSSProperties,
    ArrowDownContainer: (leftValue) => ({
        clipPath: 'polygon(100% 100%, 0% 100% , 0.00% 100.00%, 3.33% 99.70%, 6.67% 98.82%, 10.00% 97.40%, 13.33% 95.49%, 16.67% 93.19%, 20.00% 90.59%, 23.33% 87.80%, 26.67% 84.95%, 30.00% 82.17%, 33.33% 79.57%, 36.67% 77.26%, 40.00% 75.36%, 43.33% 73.94%, 46.67% 73.06%, 50.00% 72.76%, 53.33% 73.06%, 56.67% 73.94%, 60.00% 75.36%, 63.33% 77.26%, 66.67% 79.57%, 70.00% 82.17%, 73.33% 84.95%, 76.67% 87.80%, 80.00% 90.59%, 83.33% 93.19%, 86.67% 95.49%, 90.00% 97.40%, 93.33% 98.82%, 96.67% 99.70%, 100.00% 100.00%)',
        width: `${TOP_COLLAPSE_BUTTON_PART_WIDTH}px`,
        height: '85px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        position: 'absolute',
        left: `${leftValue}px`, // center of the screen
        backgroundColor: 'white',
        transform: 'scaleY(-1)',
        top: 0,
        cursor: 'pointer'
    }) as React.CSSProperties,
    ArrowDown: {
        transform: 'scaleY(-1)',
        fontSize: '50px',
        position: 'absolute', top: '53px'
    } as React.CSSProperties,
    HeaderContainer: {
        color: 'white',
        textAlign: 'center',
        fontSize: '1.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        padding: '0px 24px'
    } as React.CSSProperties,
    headerCategoryButton: {
        padding: '4px 6px',
        borderRadius: '10px',
        border: '1px solid var(--peach900)',
        fontSize: '0.8rem',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        letterSpacing: '1px',
        margin: '0 6px'
    } as React.CSSProperties,
    OptionsContainer: (onlyOneElemShown) => ({
        height: onlyOneElemShown ? '100%' : 'fit-content',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: `${OPTIONS_CONTAINER_GAP_VALUE}px`,
        marginTop: `${OPTIONS_CONTAINER_MARGIN_TOP_VALUE}px`,
        paddingBottom: `${OPTIONS_CONTAINER_PADDING_TOP_BOTTOM_VALUE}px`,
        justifyContent: isMobile() ? 'space-evenly' : 'center',
        alignItems: onlyOneElemShown ? 'center' : isMobile() ? 'baseline' : 'flex-start',
        zIndex: 11
    }) as React.CSSProperties,
    baseCornerStyle: (cornerOpacity) => ({
        position: 'absolute',
        top: '-22.7px',
        height: '50px',
        width: '15px',
        backgroundColor: 'transparent',
        boxShadow: 'white 0px -15px 0px 0px',
        transform: 'rotate(180deg)',
        transition: 'all 0.3s ease',
        opacity: cornerOpacity,
        borderTop: '1px solid var(--peach900)',
        zIndex: 0
    }) as React.CSSProperties,
    leftCornerStyle: (cornerOpacity) => ({
        ...Styles.baseCornerStyle(cornerOpacity),
        right: '-15px',
        borderTopRightRadius: '15px',
    }) as React.CSSProperties,
    rightCornerStyle: (cornerOpacity) => ({
        ...Styles.baseCornerStyle(cornerOpacity),
        left: '-15px',
        borderTopLeftRadius: '15px',
    }) as React.CSSProperties,
    mainContainerBackgroundGradientStyle: {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: "0px",
        bottom: "0px",
        borderRadius: "15px",
        backgroundBlendMode: "screen",
    } as React.CSSProperties,
    fadingBorderStyle: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '60px', // adjust height for the gradient
        background: 'linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0))',
        pointerEvents: 'none',
        borderRadius: '15px',
        zIndex: 10
    } as React.CSSProperties
}

let isInSearch = false;

type TMetrics = {
    rows: number;
    itemWidth: number;
    itemHeight: number;
    itemsPerRow: number;
    finalHeight: number;
    containerWidth: number;
    preFinalHeight: number;
    doAllItemsFit: boolean;
}

const calculate = {
    leftValue: (menuWidth) => {
        return ((menuWidth + (MAIN_CONTAINER_PADDING_X_VALUE * 2)) / 2) - (TOP_COLLAPSE_BUTTON_PART_WIDTH / 2);
    },
    metrics: (containerWidth: number, itemHeight: number, totalItems: number, paddingHeight: number): TMetrics => {
        const MAX_HEIGHT_MOBILE = Math.floor(window.innerHeight * 0.8);
        const MAX_HEIGHT_DESKTOP = Math.floor(window.innerHeight * 0.9);
        itemHeight += OPTIONS_CONTAINER_GAP_VALUE * 2;

        const itemWidth = MAX_ITEM_HEIGHT_WIDTH + (OPTIONS_CONTAINER_GAP_VALUE); // Apply maxWidth constraint + gap width
        const itemsPerRow = Math.floor(containerWidth / itemWidth);
        const rows = Math.ceil(totalItems / itemsPerRow);
        let finalHeight: number = (rows * itemHeight) + paddingHeight + OPTIONS_CONTAINER_MARGIN_TOP_VALUE + OPTIONS_CONTAINER_PADDING_TOP_BOTTOM_VALUE + FINAL_HEIGHT_ADDITIONAL_SPACE;
        const preFinalHeight: number = finalHeight;
        // adjust the height to the limit.
        if (isMobileOrTablet()) {
            finalHeight = finalHeight > MAX_HEIGHT_MOBILE ? MAX_HEIGHT_MOBILE : finalHeight;
        } else {
            finalHeight = finalHeight > MAX_HEIGHT_DESKTOP ? MAX_HEIGHT_DESKTOP : finalHeight;
        }

        const finalMetrics = {
            containerWidth,
            itemWidth,
            itemHeight,
            itemsPerRow,
            rows,
            finalHeight,
            preFinalHeight,
            doAllItemsFit: preFinalHeight - finalHeight < itemHeight // if the last row is visible
        }

        return finalMetrics;
    }
}

const MenuContent = (props) => {
    const { i18n } = useTranslation();
    const { errandId } = useErrandContext();
    const windowDimensions = useWindowDimensions();
    const { chatInputFieldRef } = useFooterContext();
    const { footerDimensionsState } = useFooterDimensionsState();

    const menuContainerRef = React.useRef(null);
    const prevWindowDimensionsRef = React.useRef({ innerHeight: 0, innerWidth: 0 })
    const topCollapseContainerPartRef = React.useRef(null);

    // const [searchShown, setSearchShown] = useState(false);
    // const [resetSearchBarFlag, setResetSearchBarFlag] = useState(false);
    // const [headerSearchBarDisplayedVal, setHeaderSearchBarDisplayedVal] = useState(0); // 0 is header, 1 is searchBar
    const [menuDimensions, setMenuDimensions] = useState({ width: null, height: null });
    const [runtimeMetrics, setRuntimeMetrics] = React.useState<TMetrics>(calculate.metrics(
        menuDimensions.width,
        MAX_ITEM_HEIGHT_WIDTH,
        props.listData.length,
        footerDimensionsState.conversationFooter.height
    ));

    const leftValue = calculate.leftValue(menuDimensions.width);
    const prevDoAllItemsFitRef = React.useRef<boolean>(runtimeMetrics.doAllItemsFit);
    // const resetSearchBar = () => (headerSearchBarDisplayedVal === 1 && setResetSearchBarFlag((prev) => true));

    const clearMenuInputState = React.useCallback(() => {
        // clear when user is in search and resizes the window.
        // clear the searching state for both cases.
        props.setListData(props.parsedWorkflows);
        props.setHighlightedList([]);
        isInSearch = false;
        // make sure the input is cleared as well.
        // if some prompt was selected, do not clear it
        // only if none was actually selected
        if(AppsMenuExternalState.selectedPromptTools.wasRecentlySelected() === false) {
            chatInputFieldRef.current?.update('');
        }
    }, [
        props.setListData,
        props.setHighlightedList,
        props.parsedWorkflows
    ])

    const updateRuntimeMetrics = React.useCallback((overwriteHeight?: number) => {
        let newMetrics = calculate.metrics(
            menuDimensions.width,
            MAX_ITEM_HEIGHT_WIDTH,
            props.listData.length,
            footerDimensionsState.conversationFooter.height
        )
        // handle the overwriteHeight val
        newMetrics = {
            ...newMetrics,
            finalHeight: overwriteHeight ?? newMetrics.finalHeight
        };

        let finalMetrics = {
            ...newMetrics
        };
        if (isInSearch) {
            // keep the old doAllItemsFit
            // because this controls the search functionality
            // meaing that if all items fit ---> highlight the searched elements while others are dimmed
            // if all items does NOT fit ----> filter out those that are not searched for.
            finalMetrics = {
                ...newMetrics,
                doAllItemsFit: prevDoAllItemsFitRef.current
            };
        }

        setRuntimeMetrics(finalMetrics);
    }, [
        menuDimensions.width,
        props.listData,
        footerDimensionsState.conversationFooter.height,
        windowDimensions.innerHeight, windowDimensions.innerWidth
    ])

    const updateHeight = (initialHeightValue, yDelta) => {
        updateRuntimeMetrics(initialHeightValue - yDelta);
    }

    useDragDown(topCollapseContainerPartRef, props.closeMenu, updateHeight, runtimeMetrics.finalHeight);

    // run on each rerender with throttled effect
    React.useEffect(() => {
        updateRuntimeMetrics();
    }, [updateRuntimeMetrics]);

    // on menu container resize, update the menu dimensions data
    useResizeObserver(menuContainerRef, ({ width, height }) => {
        setMenuDimensions({ width, height })
    }, { width: true, height: false }); // observe only width.

    // handle outside clicks to close the menu.
    useClickOutside([menuContainerRef, props.conversationFooterRef, props.conversationFooterRowRef], isMobile(), props.closeMenu);

    // const showSearchBar = (e) => {
    //     setSearchShown(true);
    // }

    /**
     * Main search handler.
     * If all items fit in container ---> highlight/dim the needed search elements.
     * If some items does not fit in the container (if the last row is invisible) ---> filter out those that are not searched for AND update the height of the container.
     */
    const onSearch = React.useCallback((query: string) => {

        // if all items are fitting, no need to do the small screen search functionality
        const isCompactView = runtimeMetrics.doAllItemsFit === false && (isMobile() || window.innerWidth < BIG_SCREEN_MIN_WIDTH_VALUE);

        // If empty search value (user cleared the search input)
        if (query === '') {
            // in mobile or too small window width.
            if (isCompactView) {
                props.setListData(props.parsedWorkflows)
            }
            // desktop view OR wide window width
            else {
                // show all
                props.setHighlightedList([]);
            }

            isInSearch = false;
        }
        else {
            if (isCompactView) {
                props.setListData(
                    props.parsedWorkflows
                        .map((item, index) => ({
                            ...item,
                            originalIndex: index, // Add the original index to each item
                        }))
                        .filter((item) => {
                            const name = item.name[i18n.language] || "";
                            const description = item.description || "";
                            return (
                                name.toLowerCase().includes(query.toLowerCase()) ||
                                description.toLowerCase().includes(query.toLowerCase())
                            );
                        })
                        .sort((a, b) => {
                            const nameA = a.name[i18n.language]?.toLowerCase() || "";
                            const nameB = b.name[i18n.language]?.toLowerCase() || "";
                            return nameA.localeCompare(nameB);
                        })
                )
            }
            else {
                const filteredAndSortedData = props.parsedWorkflows
                    .map((item, index) => ({
                        ...item,
                        originalIndex: index, // Add the original index to each item
                    }))
                    .filter((item) => {
                        const name = item.name[i18n.language] || "";
                        const description = item.description || "";
                        return (
                            name.toLowerCase().includes(query.toLowerCase()) ||
                            description.toLowerCase().includes(query.toLowerCase())
                        );
                    })
                    .sort((a, b) => {
                        const nameA = a.name[i18n.language]?.toLowerCase() || "";
                        const nameB = b.name[i18n.language]?.toLowerCase() || "";
                        return nameA.localeCompare(nameB);
                    });

                // Extract the original indexes of the filtered and sorted elements
                const affectedIndexes = filteredAndSortedData.map((item) => item.originalIndex);

                // affectedIndexes are the indexes of shown elements, so update the visible arr as needed
                const updatedVisibleList = props.visibleList.map((_, index) => affectedIndexes.includes(index));

                // show only those that were filtered
                props.setHighlightedList(updatedVisibleList);
            }

            isInSearch = true;
        }

    }, [
        props.visibleList, // whenever the visible list elements changes
        runtimeMetrics.doAllItemsFit, // when doAllItemsFit changes to True or False which means the screen size was changed
        props.parsedWorkflows, // whenever more new workflows data is fetched
        footerDimensionsState.conversationFooter.width // dimensions changes
    ])

    // Update prevRuntimeMetricsRef AFTER runtimeMetrics changes
    React.useEffect(() => {
        // if in search, stop listening to doAllItemsFit changes to make
        // sure we do not overwrite the previos value BEFORE search process started
        if (isInSearch) return;
        prevDoAllItemsFitRef.current = runtimeMetrics.doAllItemsFit;
    }, [runtimeMetrics]);

    // Handle transition from all items fit view to compact view and vice versa
    // DURING the search process.
    React.useEffect(() => {
        if(isMobile()) return;

        // while in search mode
        if (isInSearch === true
            && (prevWindowDimensionsRef.current.innerHeight !== windowDimensions.innerHeight
                || prevWindowDimensionsRef.current.innerWidth !== windowDimensions.innerWidth)
        ) {
            clearMenuInputState();
        }
    }, [windowDimensions.innerHeight, windowDimensions.innerWidth, clearMenuInputState]);

    // on mobile, clear the menu input search field value ONLY when orientation changes, NOT when the dimensions change
    useEffect(() => {
        if(!isMobile()) return;

        const handleOrientationChange = () => {
            clearMenuInputState();
        };

        // Add event listener for orientation change
        window.addEventListener('orientationchange', handleOrientationChange);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, [clearMenuInputState]); // Empty dependency array means it runs once when the component mounts

    // handle previous dimensions update
    React.useEffect(() => {
        prevWindowDimensionsRef.current = {
            innerHeight: windowDimensions.innerHeight,
            innerWidth: windowDimensions.innerWidth
        }
    }, [windowDimensions.innerHeight, windowDimensions.innerWidth])

    // Handle the input box values
    React.useEffect(() => {
        const handleFooterInputStorageUpdate = (eventData) => {
            if (eventData?.detail) {
                // chat_id here is the errand id where the event was originated from.
                const { chat_id, value } = eventData.detail;

                // use value and chat_id
                // see if the curr_chat id is the same as the current errand on UI.
                if (chat_id === errandId) {
                    onSearch(value);
                }
            }
        }
        eventBus.on('footer_user_value_updated', handleFooterInputStorageUpdate);

        return () => {
            eventBus.remove('footer_user_value_updated', handleFooterInputStorageUpdate);
        }
    }, [onSearch, errandId]);

    React.useEffect(() => {
        if (!props.displayed) {
            clearMenuInputState();
        }
    }, [props.displayed, clearMenuInputState])

    const showNotFound = (
        props.displayed
        && (props.highlightedList.length !== 0 && !props.highlightedList.some((iter) => iter === true))
        || props.listData.length === 0
        && isInSearch === true
    );

    const mainContainerStyle = Styles.MainContainer(
        props.shown,
        footerDimensionsState.conversationFooter.height,
        showNotFound && !runtimeMetrics.doAllItemsFit ? 250 : runtimeMetrics.finalHeight,
        footerDimensionsState.conversationFooterRow.width - (CONTAINER_LEFT_POS_VALUE * 2)
    )

    if (props.displayed) return (
        <div
            ref={menuContainerRef}
            style={mainContainerStyle}
            className={CssClasses.footerPartBaseClass}
        >
            <div
                className={CssClasses.backgroundGradient}
                style={Styles.mainContainerBackgroundGradientStyle}
            />
            <div style={Styles.fadingBorderStyle} />
            <div ref={topCollapseContainerPartRef} style={Styles.TopCollapseContainerPart(leftValue)}>
                {/* Left corner */}
                {/* {cornersDisplayed && <div style={leftCornerStyle(cornerOpacityValue)} />} */}
                <div style={Styles.leftCornerStyle(1)} />
                {/* Right Corner */}
                {/* {cornersDisplayed && <div style={rightCornerStyle(cornerOpacityValue)} />} */}
                <div style={Styles.rightCornerStyle(1)} />
                <span style={Styles.topCollapseContainerText}>app gallery</span>
            </div>
            <div style={Styles.OptionsContainer(props.listData.length === 1)} className={CssClasses.scrollable}>
                <OptionsList
                    highlightedList={props.highlightedList}
                    menuWidth={menuDimensions.width}
                    visibleList={props.visibleList}
                    closeMenu={props.closeMenu}
                    data={props.listData}
                />
                {/* If either nothing is highlighted OR none is shown at all */}
                <NotFound show={showNotFound} />
            </div>
        </div>
    )
}

export default MenuContent;