import React from "react";
import { Box, Typography, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";

const FadeIn = ({ show, children }) => {
    return (
        <Fade in={show} timeout={300}>
            <div>{children}</div>
        </Fade>
    );
};

const containerStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2, // Slightly rounded corners
    boxShadow: 1, // Subtle shadow for depth
    textAlign: "center",
    p: 2, // Padding for spacing
    position: 'absolute',
    bottom: 0,
    left: 0
}

const textStyle = {
    fontWeight: 600,
    letterSpacing: "0.5px",
    padding: '24px',
    backgroundColor: 'white',
    color: 'var(--peach900)',
    fontFamily: 'Poppins',
    borderRadius: '15px',
    border: '0.5px solid var(--peach900)',
    marginBottom: '10%'
}

const NotFound = (props: { show: boolean }) => {
    const { t } = useTranslation()

    const [display, setDisplay] = React.useState(props.show);
    const tmIdRef = React.useRef(null);

    React.useEffect(() => {
        if (props.show) {
            setDisplay(true);
        } else {
            tmIdRef.current = setTimeout(() => setDisplay(false), 500);
        }
    }, [props.show])

    React.useEffect(() => {
        return () => {
            clearTimeout(tmIdRef.current);
        }
    }, [])

    if (!display) return <></>;
    return (
        <Box sx={containerStyle}>
            <FadeIn show={props.show}>
                <Typography
                    variant="h6"
                    color="textSecondary"
                    sx={textStyle}
                >
                    {t('AM_NotFound')}
                </Typography>
            </FadeIn>
        </Box>
    );
};

export default NotFound;
