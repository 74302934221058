import React, { useEffect } from 'react';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useFooterDimensionsState } from './hooks/useFooterDimensionsState';
import { TConversationFooterProps } from '@components/ConversationFooter';
import { isMobileOrTablet, isMobile } from '@common/deviceTypeHelper';
import { IErrand, IUserChatAction } from '@interfaces/Conversation';
import ForgotPasswordButton from '@components/ForgotPasswordButton';
import { useAppsMenuState } from './hooks/useAppsMenuState';
import { FooterActionIcon } from '@components/FooterActionIcon';
import { useFooterContext } from '@contexts/FooterContext';
import { useErrandContext } from '@contexts/ErrandContext';
import { ValidatorFunctions } from '@common/Validators';
import { useRootContext } from '@contexts/RootContext';
import CloseIcon from '@mui/icons-material/Close';
import { useUserContext } from '@contexts/user';
import AppsIcon from '@mui/icons-material/Apps';
import { useTranslation } from 'react-i18next';
import { MorphType } from '@common/MorphType';
import { Fade } from '@mui/material';
import eventBus from '@common/eventBus';
import { ScrollHandlerEventsConfig } from '@components/ScrollHandler';
import { copyObj } from '@common/userMessagesUtils';
import { AccessType } from '@common/AccessType';
import { sendWorkflow } from '@common/WorkflowsUtils';
import { WORKFLOW_NAMES } from '@constants/WorkflowNames';


const Styles = {
    DisplayActionIconWrapper: { marginTop: '-7px' } as React.CSSProperties,
    ActionIconButtonSpan: (fontSize = null) => ({ position: 'absolute', bottom: '3px', fontSize: fontSize ?? '12px', fontWeight: 'bold' }) as React.CSSProperties,
    ButtonSpan: (fontSize = null) => ({ paddingBottom: '2px', fontSize: fontSize ?? '0.8rem', transform: 'translateY(-5px)', fontWeight: 'bold', textAlign: 'center' }) as React.CSSProperties,
    ButtonImgIcon: { paddingTop: isMobileOrTablet() ? '5px' : '9px', width: '24px', paddingBottom: '7px' } as React.CSSProperties,
    ButtonCloseIcon: (fontSize = null) => ({ paddingTop: '2px', fontSize: fontSize ?? '2.2rem' }) as React.CSSProperties,
    ButtonWrapper: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
        position: "absolute",
        width: '100%',
        height: '100%'
    } as React.CSSProperties,
    ButtonWrapperContainer: (chatSearchIsOn, calculatedBtnHeight, calculatedBtnWidth, showActionIcon, isPressed) => ({
        display: "flex",
        height: `${calculatedBtnHeight}px`,
        width: `${calculatedBtnWidth}px`,
        justifyContent: "center",
        alignItems: "center",
        // alignSelf: "flex-end",
        padding: "6px",
        backgroundColor: chatSearchIsOn ? "var(--blue850)" : "var(--peach900)",
        boxShadow: isPressed ? '0px 1px 1px -1px rgba(0,0,0,0.15)' : '0px 2px 2px 1px rgba(0,0,0,0.35)',
        transform: isPressed ? 'translateY(0)' : 'translateY(-1px)',
        borderRadius: "6px",
        marginBottom: "10px",
        paddingBottom: '5px',
        flexDirection: 'column',
        marginRight: '6px',
        color: 'white',
        cursor: 'pointer',
        position: 'relative',
        transition: 'all 0.2s ease',

    }) as React.CSSProperties
}


type TOtherAppsMenuBtnProps = {
    setErrands: React.Dispatch<React.SetStateAction<IErrand[]>>,
    isCurrActionRelatedToPassword: boolean,
    fieldAttributeDescription?: string,
    errandAction: IUserChatAction,
    errandIcon: string,
    isDesktop: boolean,
    isUser: boolean,
}

type THasCancelActionProp = {
    cancelAction: (key: any, clear?: boolean, withMorphTypeChange?: boolean) => Promise<void>,
}

export const appsMenuButtonTools = {
    getIsHidden: (morphType, errand, isOperator) => {
        return (morphType !== MorphType.None // morphType is not none
            || (
                errand?.action?.fieldAttribute?.description === 'DROPDOWN'
                || errand?.action?.action?.fieldAttribute?.description === 'DROPDOWN') === true
            || isOperator)
    }
}

const DisplayActionIcon = ({
    isCurrActionRelatedToPassword,
    errandAction,
    errandIcon,
    isDesktop
}: TOtherAppsMenuBtnProps) => {
    /**
     * NOTE
     * A) when a handleClickEdit is called in MessageContentWrapper, 
     * errandAction gets changed to a structure that is different 
     * from the one that is being used in actionDialog B) edit click handler.
     * 
     * In option A) errandAction doesn't have nested attr "action"
     * While option B) does.
     * In other words A) errandAction.action is undefined (errandAction has everything needed)
     * while B) errandAction.action is defined and has all the needed attributes
     * 
     * THUS, the following code resolves this difference.
     * 
     * TODO: resolve this discrepancy between different structures
     *  */

    let actionRef = errandAction?.action;
    // if so, use first level action, else leave nested one.
    if (!actionRef) {
        actionRef = errandAction;
    }
    // use the calculated reference to access needed props
    const actionId = actionRef?._id;
    const animatedIcon = actionRef?.animatedIcon || "";

    return (
        <FooterActionIcon
            actionId={actionId}
            base64Icon={errandIcon}
            withPulsingAnimation={false} // make sure we are not triggering that pulsing animation
            _invertedBase64Icon={animatedIcon}
            filterOvverideString='brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);'
        />
    )
}


const CancelledBackDownButton = ({ visibilityBool }) => {
    const { t } = useTranslation();

    return (
        <Fade in={visibilityBool} timeout={300}>
            <div style={Styles.ButtonWrapper}>
                <KeyboardDoubleArrowDownIcon sx={Styles.ButtonCloseIcon('2rem')} />
                <span style={Styles.ButtonSpan()}>{t('AMB_Back_Down')}</span>
            </div>
        </Fade>
    )
}

const CancelSearchViewButton = ({ visibilityBool }) => {
    const { t } = useTranslation();

    return (
        <Fade in={visibilityBool} timeout={300}>
            <div style={Styles.ButtonWrapper}>
                <CloseIcon sx={Styles.ButtonCloseIcon()} />
                <span style={Styles.ButtonSpan()}>{'Cancel'}</span>
            </div>
        </Fade>
    )
}

const SearchModeBackDownButton = ({ visibilityBool }) => {
    const { t } = useTranslation();

    return (
        <Fade in={visibilityBool} timeout={300}>
            <div style={Styles.ButtonWrapper}>
                <KeyboardDoubleArrowDownIcon sx={Styles.ButtonCloseIcon('2rem')} />
                <span style={Styles.ButtonSpan('0.7rem')}>{t('AMB_Back_Down')}</span>
            </div>
        </Fade>
    )
}


const ActionButton = ({
    visibilityBool,
    isCurrActionRelatedToPassword,
    errandAction,
    errandIcon,
    setErrands,
    isDesktop,
    isUser,
    isOTP = false
}) => {
    const { t, i18n } = useTranslation();

    const isVietLang = i18n.language === 'vi'; // vietnameese lang
    const isHtLang = i18n.language === 'ht'; // creole lang

    const isRussianLang = i18n.language === 'ru'; // russian lang
    const isArabicLang = i18n.language === 'ar'; // arabic lang
    const isFrenchLang = i18n.language === 'fr'; // french lang
    const isPortugLang = i18n.language === 'pt'; // portugeese lang
    const isGermanLang = i18n.language === 'de'; // german lang
    const isPolishLang = i18n.language === 'pl'; // portugeese lang

    if (isOTP) {
        const finalFontSize =
        isPolishLang ? '11px'
            : isArabicLang || isFrenchLang || isPortugLang || isGermanLang
                ? '10px'
                : isRussianLang
                    ? '9px' : null;

        return (
            <Fade in={visibilityBool} timeout={300}>
                <div style={Styles.ButtonWrapper}>
                    <div style={Styles.DisplayActionIconWrapper}>
                        <DisplayActionIcon
                            isCurrActionRelatedToPassword={isCurrActionRelatedToPassword}
                            errandAction={errandAction}
                            errandIcon={errandIcon}
                            setErrands={setErrands}
                            isDesktop={isDesktop}
                            isUser={isUser}
                        />
                    </div>
                    <span style={Styles.ActionIconButtonSpan(finalFontSize)}>{t('resendOTP')}</span>
                </div>
            </Fade>
        )
    }

    return (
        <Fade in={visibilityBool} timeout={300}>
            <div style={Styles.ButtonWrapper}>
                <div style={Styles.DisplayActionIconWrapper}>
                    <DisplayActionIcon
                        isCurrActionRelatedToPassword={isCurrActionRelatedToPassword}
                        errandAction={errandAction}
                        errandIcon={errandIcon}
                        setErrands={setErrands}
                        isDesktop={isDesktop}
                        isUser={isUser}
                    />
                </div>
                <span style={Styles.ActionIconButtonSpan((isVietLang || isHtLang) ? '11px' : null)}>{t('AppsMenu_Stop')}</span>
            </div>
        </Fade>
    )
}

const HideMenuButton = ({ visibilityBool }) => {
    const { t } = useTranslation();

    return (
        <Fade in={visibilityBool} timeout={300}>
            <div style={Styles.ButtonWrapper}>
                <CloseIcon sx={Styles.ButtonCloseIcon()} />
                <span style={Styles.ButtonSpan()}>{t('AppsMenu_hide')}</span>
            </div>
        </Fade>
    )
}

const OpenMenuButton = ({ visibilityBool }) => {
    const { t, i18n } = useTranslation();

    const isVietLang = i18n.language === 'vi'; // vietnameese lang
    const isArLang = i18n.language === 'ar'; // arabic lang

    return (
        <Fade in={visibilityBool} timeout={300}>
            <div style={Styles.ButtonWrapper}>
                <img style={Styles.ButtonImgIcon} src={AppsMenuIcon} />
                <span style={Styles.ButtonSpan(
                    isVietLang ? isMobile() ? '0.6rem' : '0.7rem'
                        : isArLang ? '0.7rem'
                            : null
                )}>
                    {t('AppsMenu_apps')}
                </span>
            </div>
        </Fade>
    )
}

const BUTTON_UI = {
    SHOW_MENU: "SHOW_MENU",
    HIDE_MENU: "HIDE_MENU",
    STOP_ACTION: "STOP_ACTION",
    CANCELLED_BACK_DOWN: "CANCELLED_BACK_DOWN",
    SEARCH_MODE_BACK_DOWN: "SEARCH_MODE_BACK_DOWN",
    CANCEL_SEARCH_VIEW: "CANCEL_SEARCH_VIEW",
    RESEND_OTP: "RESEND_OTP"
} as const;

const getCurrentButtonUI = (
    isInSearch,
    isSearchView,
    isSearchMode,
    isAction,
    isSearchCancelled,
    isMenuOpened,
    isOtpAction = false
) => {
    // if has menu opened
    if (isMenuOpened) {
        return BUTTON_UI.HIDE_MENU;
    }

    // if in search AND not cancelled
    if (isInSearch && !isSearchCancelled) {
        if (isSearchView) {
            return BUTTON_UI.CANCEL_SEARCH_VIEW;
        }

        if (isSearchMode) {
            return BUTTON_UI.SEARCH_MODE_BACK_DOWN;
        }
    }

    // if cancelled and action is not shown
    if (isSearchCancelled && !isAction) {
        return BUTTON_UI.CANCELLED_BACK_DOWN;
    }

    if (isAction) {
        if (isOtpAction) return BUTTON_UI.RESEND_OTP;
        return BUTTON_UI.STOP_ACTION;
    }

    if (!isMenuOpened) {
        return BUTTON_UI.SHOW_MENU;
    }
}

const ButtonContent = ({
    isCurrActionRelatedToPassword,
    searchWasCancelled,
    showActionIcon,
    errandAction,
    isOtpAction,
    searchView,
    searchMode,
    errandIcon,
    setErrands,
    isDesktop,
    isOpened,
    isUser,
    errand,
}: TOtherAppsMenuBtnProps & { isOpened: boolean, errand: IErrand, showActionIcon: boolean, searchMode: boolean, searchView: boolean, searchWasCancelled: boolean, isOtpAction: boolean }) => {
    const { t, i18n } = useTranslation();

    const isInSearch = (searchView || searchMode);
    const isSearchView = searchView;
    const isSearchMode = searchMode;
    const isAction = showActionIcon;
    const isSearchCancelled = searchWasCancelled;
    const isMenuOpened = isOpened;

    const ButtonUI_ID = getCurrentButtonUI(
        isInSearch,
        isSearchView,
        isSearchMode,
        isAction,
        isSearchCancelled,
        isMenuOpened,
        isOtpAction
    );

    switch (ButtonUI_ID) {
        case BUTTON_UI.SHOW_MENU:
            return <OpenMenuButton visibilityBool={!isOpened && !showActionIcon} />;
            break;
        case BUTTON_UI.HIDE_MENU:
            return <HideMenuButton visibilityBool={isOpened && !showActionIcon} />;
            break;
        case BUTTON_UI.STOP_ACTION:
            return <ActionButton
                isCurrActionRelatedToPassword={isCurrActionRelatedToPassword}
                visibilityBool={showActionIcon}
                errandAction={errandAction}
                errandIcon={errandIcon}
                setErrands={setErrands}
                isDesktop={isDesktop}
                isUser={isUser}
            />;
            break;
        case BUTTON_UI.SEARCH_MODE_BACK_DOWN:
            return <SearchModeBackDownButton visibilityBool={searchMode} />;
            break;
        case BUTTON_UI.CANCELLED_BACK_DOWN:
            return <CancelledBackDownButton visibilityBool={searchWasCancelled} />;
            break;
        case BUTTON_UI.CANCEL_SEARCH_VIEW:
            return <CancelSearchViewButton visibilityBool={searchView} />
            break;
        case BUTTON_UI.RESEND_OTP:
            return <ActionButton
                isCurrActionRelatedToPassword={isCurrActionRelatedToPassword}
                visibilityBool={showActionIcon}
                errandAction={errandAction}
                errandIcon={errandIcon}
                setErrands={setErrands}
                isDesktop={isDesktop}
                isOTP={isOtpAction}
                isUser={isUser}
            />;
            break;
    }
}

const INPUT_CONTAINER_PADDING = 5;
const MIN_BTN_HEIGHT_WIDTH_VALUE = 49;
const BTN_HEIGHT_WIDTH_VALUE = 54;
const getButtonHeightWidth = (footerDimensionsState) => {
    const calculatedBtnHeight = (footerDimensionsState.inputContainer.height) + (INPUT_CONTAINER_PADDING * 2);
    const calculatedHeightWidth = Math.min(BTN_HEIGHT_WIDTH_VALUE, calculatedBtnHeight);
    const finalHeightWidth = Math.max(MIN_BTN_HEIGHT_WIDTH_VALUE, calculatedHeightWidth);

    return finalHeightWidth;
}
// AM_MenuIcon.svg
const AppsMenuIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/AM_MenuIcon.svg';

// for otp send process not to spam it.
let isOTPSendReqLocked = false;

const AppsMenuButton = React.memo((props: TConversationFooterProps & TOtherAppsMenuBtnProps & THasCancelActionProp) => {
    const showActionIcon = ValidatorFunctions.isNotUndefinedNorNull(props.fieldAttributeDescription) && ValidatorFunctions.isNotUndefinedNorNull(props.errandIcon);
    const { appsMenuState, setAppsMenuState } = useAppsMenuState();
    const { footerDimensionsState } = useFooterDimensionsState();
    const { isOperator, _id } = useUserContext();
    // Old, was meant to show the disclaimer popup above the send button for the consent reason.
    // Now, not needed.
    // const { returnConsentGiven, setClickedDisabledFeatures } = useRootContext();
    const footerContext = useFooterContext();
    const { morphType, footerLastMessageChangeActionLockedRef } = useErrandContext();
    const tmsRef = React.useRef([]);
    const [isPressed, setIsPressed] = React.useState(false);

    const isInSearch = (appsMenuState.searchView || appsMenuState.searchMode);
    const isSearchView = appsMenuState.searchView;
    const isSearchMode = appsMenuState.searchMode;
    const isAction = showActionIcon;
    const isOtpAction = props?.fieldAttributeDescription === "OTP";
    const isSearchCancelled = appsMenuState.searchWasCancelled;
    const isMenuOpened = appsMenuState.isOpened;

    const ButtonUI_ID = getCurrentButtonUI(
        isInSearch,
        isSearchView,
        isSearchMode,
        isAction,
        isSearchCancelled,
        isMenuOpened,
        isOtpAction
    );

    const clearInputField = () => {
        setTimeout(
            () => {
                footerContext?.sendButtonRef?.current?.update('');
                footerContext?.chatInputFieldRef?.current?.update('');
            },
            0
        );
    }

    const hideSearchView = () => {
        setAppsMenuState((prev) => {
            return {
                ...prev,
                searchView: false,
                searchMode: false
            }
        })
    }

    const cancelSearchView = () => {
        setAppsMenuState((prev) => {
            return {
                ...prev,
                searchView: false,
                searchMode: false,
                searchWasCancelled: true
            }
        })
    }

    const updateShown = (val: boolean) => {
        setAppsMenuState((prev) => {
            return {
                ...prev,
                shown: val
            }
        })
    }

    const updateDisplayed = (val: boolean) => {
        setAppsMenuState((prev) => {
            return {
                ...prev,
                displayed: val
            }
        })
    }

    const updateListShown = (val: boolean) => {
        setAppsMenuState((prev) => {
            return {
                ...prev,
                listShown: val
            }
        })
    }

    const updateIsOpened = (val: boolean) => {
        setAppsMenuState((prev) => {
            return {
                ...prev,
                isOpened: val
            }
        })
    }

    const show = () => {
        updateDisplayed(true);
        cancelSearchView();
        tmsRef.current.push(
            setTimeout(() => {
                updateShown(true);
                tmsRef.current.push(
                    setTimeout(() => {
                        updateListShown(true);
                    }, 300)
                )
            }, 100)
        );
    }

    const hide = () => {
        updateListShown(false);
        updateShown(false);
        tmsRef.current.push(
            setTimeout(() => {
                updateDisplayed(false);
            }, 500)
        )
    }

    const openMenu = () => {
        updateIsOpened(true);
    }

    const closeMenu = () => {
        updateIsOpened(false);
    }

    const onResendOTPClickHandler = async () => {
        if (isOTPSendReqLocked) {
            console.warn(`Cannot submit action, waiting for request to complete.`);
            return;
        }

        isOTPSendReqLocked = true;
        const workflowName = WORKFLOW_NAMES.RESEND_OTP;

        if (!props?.errand?.action?.action?.fieldName) {
            console.warn(`Cannot generate resend button: No action fieldName`);
            isOTPSendReqLocked = false;
            return;
        }

        if (!props?.errand._id || props.errand._id === '') {
            console.warn(`Cannot submit workflow  ${workflowName}, invalid chat id: ${props?.errand?._id}`);
            isOTPSendReqLocked = false;
            return;
        }

        if (!_id || _id === '') {
            console.warn(`Cannot submit workflow ${workflowName}, invalid user id: ${_id}`);
            isOTPSendReqLocked = false;
            return;
        }

        // This is not sent with the props.errand.recipients due to the async nature of setState, and the fact that
        // the recipients updated property will not be immediately available.
        await sendWorkflow('', workflowName, props.errand._id, [_id], AccessType.public, _id, false, null, true);
        isOTPSendReqLocked = false;
    }

    const onClickHandler = () => {
        switch (ButtonUI_ID) {
            case BUTTON_UI.SHOW_MENU:
                openMenu();
                clearInputField();
                return;
                break;
            case BUTTON_UI.HIDE_MENU:
                closeMenu();
                clearInputField();
                return;
                break;
            case BUTTON_UI.CANCELLED_BACK_DOWN:
                // scroll to bottom
                eventBus.dispatch(
                    ScrollHandlerEventsConfig.CHANNEL_ID,
                    { eventId: ScrollHandlerEventsConfig.EVENTS.FORCE_SCROLL_BOTTOM }
                );
                return;
                break;
            case BUTTON_UI.CANCEL_SEARCH_VIEW:
                // lock the footer last message changes
                footerLastMessageChangeActionLockedRef.current = true;
                props?.cancelAction(null, true, false);
                hideSearchView();
                clearInputField();
                // and also scroll to bottom
                eventBus.dispatch(
                    ScrollHandlerEventsConfig.CHANNEL_ID,
                    { eventId: ScrollHandlerEventsConfig.EVENTS.FORCE_SCROLL_BOTTOM }
                );
                return;
                break;
            case BUTTON_UI.SEARCH_MODE_BACK_DOWN:
                eventBus.dispatch(
                    ScrollHandlerEventsConfig.CHANNEL_ID,
                    { eventId: ScrollHandlerEventsConfig.EVENTS.FORCE_SCROLL_BOTTOM }
                );
                return;
                break;
            case BUTTON_UI.STOP_ACTION:
                props?.cancelAction(null, true, false);
                return;
                break;
            case BUTTON_UI.RESEND_OTP:
                onResendOTPClickHandler();
                return;
                break;
        }
    }

    useEffect(() => {
        if (appsMenuState.isOpened) {
            show();
        } else {
            hide();
        }

        return () => {
            tmsRef.current.forEach((tmId) => clearTimeout(tmId));
            tmsRef.current = [];
        }
    }, [appsMenuState.isOpened])

    if (appsMenuButtonTools.getIsHidden(morphType, props?.errand, isOperator))
        return (<></>);

    const heightWidthVal = getButtonHeightWidth(footerDimensionsState);


    const handleOnPress = () => {
        setIsPressed(true);
    }

    const handleOnRelease = () => {
        setTimeout(() => {
            setIsPressed(false)
        }, 200);
    }
    return (
        <div
            style={Styles.ButtonWrapperContainer(appsMenuState.searchMode || appsMenuState.searchView, heightWidthVal, heightWidthVal, showActionIcon, isPressed)}
            onClick={onClickHandler}
            onMouseDown={handleOnPress}
            onMouseUp={handleOnRelease}
            onMouseLeave={handleOnRelease}
            onTouchStart={handleOnPress}
            onTouchEnd={handleOnRelease}
        >
            <ButtonContent
                isCurrActionRelatedToPassword={props.isCurrActionRelatedToPassword}
                fieldAttributeDescription={props.fieldAttributeDescription}
                searchWasCancelled={appsMenuState.searchWasCancelled}
                searchMode={appsMenuState.searchMode}
                searchView={appsMenuState.searchView}
                isOpened={appsMenuState.isOpened}
                errandAction={props.errandAction}
                showActionIcon={showActionIcon}
                errandIcon={props.errandIcon}
                setErrands={props.setErrands}
                isDesktop={props.isDesktop}
                isOtpAction={isOtpAction}
                isUser={props.isUser}
                errand={props.errand}
            />
        </div>
    )
});

export default AppsMenuButton;
