import React, { useEffect } from "react";

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import TypingAnimation from '@components/FooterTypingAnimation/TypingAnimation';
import { SlideIcon } from "@components/MorphUserPromptsMenu/SingleSlideElement";
import { isMobileOrTablet } from "@common/deviceTypeHelper";
import { BIG_SCREEN_MIN_WIDTH_VALUE } from "../constants";
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import Sanitized from '@components/Sanitized';
import CssClasses from '../styles.module.css'
import { Fade } from "@mui/material";

const Styles = {
    iconDefaultStyle: {
        width: '18px',
        height: '18px',
        color: 'var(--peach900)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            color: 'var(--peach600)',
        },
        cursor: 'pointer',
        border: '1px solid var(--peach900)'
    } as React.CSSProperties,
    slideIconDefStyle: (renderSmaller) => ({
        filter: "brightness(0) saturate(100%) invert(58%) sepia(34%) saturate(3607%) hue-rotate(333deg) brightness(105%) contrast(98%)",
        width: renderSmaller ? '28px' : '43px',
        height: renderSmaller ? '28px' : '43px',
    }) as React.CSSProperties,
    infoTextStyle: {
        fontFamily: 'Poppins',
        fontSize: '0.8rem',
        position: 'absolute',
        color: 'var(--peach900)',
        top: 5,
        left: 0,
        zIndex: 4,
        padding: '0 5px',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
    } as React.CSSProperties,
    infoIconContainerStyle: (isShown) => ({
        transition: '0.5s all ease-in-out',
        position: 'absolute',
        opacity: isShown ? '1' : '0',
        visibility: isShown ? 'visible' : 'hidden',
        bottom: '-10px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 6
    }) as React.CSSProperties,
    singleItemIconContainerStyle: (renderSmaller) => ({
        width: renderSmaller ? '64px' : '77px',
        height: renderSmaller ? '64px' : '77px',
        borderRadius: '50%',
        // border: '1px solid var(--peach900)',
        backgroundColor: 'rgb(255 228 209)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    }) as React.CSSProperties,
    iconTextStyle: (biggerText) => {
        return {
            alignSelf: 'center',
            lineHeight: biggerText ? '15px' : '13px',
            fontSize: biggerText ? '0.9rem' : '0.8rem'
        } as React.CSSProperties
    },
    mainContainer: {
        flexDirection: 'column',
        position: 'relative',
        display: 'flex',
    } as React.CSSProperties,
    dissatisfiedIconStyle: {
        filter: "brightness(0) saturate(100%) invert(58%) sepia(34%) saturate(3607%) hue-rotate(333deg) brightness(130%) contrast(110%)",
        width: '2.675rem',
        height: '2.675rem'
    } as React.CSSProperties
}

const SingleListItemContent = (props) => {
    const [displayTypingAnimation, setDisplayTypingAnimation] = React.useState(false);
    const removeDisplayTypingAnimationTmRef = React.useRef(null);

    // handle display of typing animation gracefully
    useEffect(() => {
        if (props.listItemStateData?.infoShown === true) {
            setDisplayTypingAnimation(true);
            clearTimeout(removeDisplayTypingAnimationTmRef.current);
        } else {
            removeDisplayTypingAnimationTmRef.current = setTimeout(() => {
                setDisplayTypingAnimation(false);
            }, 600);
        }
    }, [props.listItemStateData?.infoShown])

    useEffect(() => {
        // on unmount clear timeout
        return () => {
            clearTimeout(removeDisplayTypingAnimationTmRef.current)
        }
    }, [])

    const shouldRenderSmallerIcons = isMobileOrTablet() || window.innerWidth < BIG_SCREEN_MIN_WIDTH_VALUE;

    return (
        <>
            <Fade in={props.listItemStateData.infoShown} timeout={600}>
                <div style={Styles.infoTextStyle}>
                    {displayTypingAnimation && <TypingAnimation text={props.item.description} speed={35}/>}
                </div>
            </Fade>
            <Fade in={!props.listItemStateData.infoShown} timeout={600}>
                <div style={Styles.mainContainer}>
                    <div style={Styles.singleItemIconContainerStyle(shouldRenderSmallerIcons)}>
                        <SlideIcon
                            workflowIcon={props.item?.workflowIcon}
                            otherStyles={Styles.slideIconDefStyle(shouldRenderSmallerIcons)}
                        />
                    </div>
                    <p
                        style={Styles.iconTextStyle(shouldRenderSmallerIcons)}
                        className={CssClasses.SingleItemParagraph}>
                        {props.item.name[props.currLang]}
                    </p>
                </div>
            </Fade>
        </>
    )
}


const SingleListItemInformationIcon = (props) => {
    return (
        <div
            style={Styles.infoIconContainerStyle(props.isHovered || props.listItemStateData.infoShown)}
        >
            {props.listItemStateData.infoShown
                ? (
                    <CancelIcon
                        sx={Styles.iconDefaultStyle}
                        onClick={props.onSingleItemInfoClickHandler}
                    />
                )
                : (
                    <InfoIcon
                        sx={Styles.iconDefaultStyle}
                        onClick={props.onSingleItemInfoClickHandler}
                    />
                )
            }

        </div>
    )
}


const SingleListItem = (props) => {
    return props.optionsListState?.[props.index]?.error === null
        ? (
            <>
                {/* INFORMATION ICON */}
                <SingleListItemInformationIcon
                    index={props.index}
                    isHovered={props.isHovered}
                    listItemStateData={props.listItemStateData}
                    onSingleItemInfoClickHandler={props.onSingleItemInfoClickHandler}
                />
                <SingleListItemContent
                    item={props.item}
                    currLang={props.currLang}
                    listItemStateData={props.listItemStateData}
                />
            </>
        ) // handles the error state of the single clicked workflow option.
        : (
            <>
                <SentimentVeryDissatisfiedIcon sx={Styles.dissatisfiedIconStyle}/>
                <Sanitized html={props.optionsListState?.[props.index]?.error} />
            </>
        )
}

export default SingleListItem;