import React from 'react';
import { TWorkflowData_UPmenu } from '@components/MorphUserPromptsMenu/types';
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SingleListItemWrapper from './SingleListItemWrapper';
import { fetchSampleTextData } from '@components/MorphUserPromptsMenu/api';
import { useFooterContext } from '@contexts/FooterContext';
import { ValidatorFunctions } from '@common/Validators';
import { MAX_ITEM_HEIGHT_WIDTH, OPTIONS_CONTAINER_GAP_VALUE } from '../constants';
import { isMobile } from '@common/deviceTypeHelper';
import { AppsMenuExternalState } from '../AppsMenu';
import { useRootContext } from '@contexts/RootContext';
import { useUserContext } from '@contexts/user';
import eventBus from '@common/eventBus';

const defaultFlexStyle = {
    ...(isMobile()
        ? {
            minHeight: `${MAX_ITEM_HEIGHT_WIDTH}px`,
            minWidth: `${MAX_ITEM_HEIGHT_WIDTH}px`,
        }
        : {
            maxHeight: `${MAX_ITEM_HEIGHT_WIDTH}px`,
            maxWidth: `${MAX_ITEM_HEIGHT_WIDTH}px`,
        }
    ),
    position: 'relative',
    aspectRatio: '1'
};

const Styles = {
    SingleListItemMobile: () => ({
        ...defaultFlexStyle,
        // ...(screenWidth < 430 ? { flexBasis: `calc(33%)` } : { flex: `1 1 calc(33%)` })
        flexBasis: `calc(33%)`
    }) as React.CSSProperties,
    SingleListItem: () => ({
        ...defaultFlexStyle,
        flex: `1 1 calc(33%)`
    }) as React.CSSProperties
}

type TOptionsListProps = {
    menuWidth: number;
    closeMenu: () => void;
    visibleList: boolean[];
    highlightedList: boolean[];
    data: TWorkflowData_UPmenu[];
    // resetSearchBar: () => void;
}

type TOptionsListState = { isLoading: boolean, infoShown: boolean, error: null | string }[];
const getInitialOptionsListState = (ln) => new Array(ln).fill({ isLoading: false, error: null, infoShown: false });

const OptionsList = (props: TOptionsListProps) => {
    const itemWasRecentlyClickedRef = React.useRef(false);
    const itemWasRecentlyClickedTMRef = React.useRef(null);
    const { i18n } = useTranslation();
    const footerContext = useFooterContext();
    const {
        returnConsentGiven,
        setClickedDisabledFeatures
    } = useRootContext();
    const [optionsListState, setOptionsListState] = React.useState<TOptionsListState>(getInitialOptionsListState(props.data.length));

    React.useEffect(() => {
        setOptionsListState(getInitialOptionsListState(props.data.length));
    }, [props.data.length])

    React.useEffect(() => {
        return () => {
            clearTimeout(itemWasRecentlyClickedTMRef.current);
            itemWasRecentlyClickedRef.current = false;
        }
    }, [])

    const updateSingleIsLoadingElement = (index, val) => {
        setOptionsListState((prev) => {
            const updatedListState = [...prev];
            updatedListState[index] = { ...updatedListState[index], isLoading: val };
            return updatedListState;
        });
    }

    const updateSingleErrorElement = (index, msg) => {
        setOptionsListState((prev) => {
            const updatedListState = [...prev];
            updatedListState[index] = { ...updatedListState[index], error: msg };
            return updatedListState;
        });
    }

    const toggleSingleInfoElement = (index) => {
        setOptionsListState((prev) => {
            const updatedListState = [...prev];
            updatedListState[index] = { ...updatedListState[index], infoShown: !updatedListState[index].infoShown };
            return updatedListState;
        });
    }

    const hideSingleInfoElement = (index) => {
        setOptionsListState((prev) => {
            const updatedListState = [...prev];
            updatedListState[index] = { ...updatedListState[index], infoShown: false };
            return updatedListState;
        });
    }

    const updateUnlockItemClickedTM = () => {
        clearTimeout(itemWasRecentlyClickedTMRef.current);
        itemWasRecentlyClickedTMRef.current = setTimeout(() => {
            itemWasRecentlyClickedRef.current = false;
        }, 600);
    }


    const onSingleItemClickHandler = async (clickedIdx) => {
        if (itemWasRecentlyClickedRef.current) {
            updateUnlockItemClickedTM();
            return;
        };
        if (optionsListState[clickedIdx].infoShown) return;

        itemWasRecentlyClickedRef.current = true;
        AppsMenuExternalState.selectedPromptTools.lock();
        updateUnlockItemClickedTM();

        // set the elem to loading state
        updateSingleIsLoadingElement(clickedIdx, true);
        // fetch random sample text
        let sampleText = null
        // if user clicks on it the first time
        const clickedWorkflowData = props.data[clickedIdx];
        // prepare new root user selected prompt info ref data
        const wfIdName = clickedWorkflowData.idName.replaceAll(' ', '_');
        try {
            // fetch random sample text
            const { data } = await fetchSampleTextData(wfIdName);
            const { sample } = data;
            sampleText = sample;
        } catch (err) {
            // reset search bar
            // props.resetSearchBar();
            updateSingleIsLoadingElement(clickedIdx, false);
            console.error('Error occured while fetching workflow random sample text data', err);
            updateSingleErrorElement(clickedIdx, "Some Error Occured.</br>Try again later.")
            // close in 2 seconds
            setTimeout(() => props.closeMenu(), 2000);
        }
        if (ValidatorFunctions.isUndefinedOrNull(sampleText) === true || (ValidatorFunctions.isTypeOfString(sampleText) && sampleText.length === 0)) {
            // reset search bar
            // props.resetSearchBar();
            updateSingleIsLoadingElement(clickedIdx, false);
            console.error('Error occured after fetching workflow random sample text data', 'sampleText is undefined or null or empty string!', { sampleText: sampleText });
            updateSingleErrorElement(clickedIdx, "Some Error Occured.</br>Try again later.")
            // close in 2 seconds
            setTimeout(() => props.closeMenu(), 2000);
            return;
        }

        // if we got here, it means we received the valid sample text from the back-end and can proceed further
        // Handle Workflow Selection.
        // setTimeout is used for smooth animation here.
        setTimeout(() => {
            props.closeMenu();
            // play send button icon animation
            // focus on send button
            footerContext.chatInputFieldRef.current?.update(sampleText);
            // setTimeout is used to ensure that the sendButton gets focused and focus is not lost by browser.
            // without it, it does not focus sendButton. Bahavior may vary on different browsers.
            setTimeout(() => {
                // if (!tpConsentGiven || !returnConsentGiven) {
                //     setClickedDisabledFeatures(true);
                //     eventBus.dispatch('showConsentContent');
                // } else {
                // Send directly in chat
                // @ts-ignore
                footerContext.sendButtonRef.current?.handleSubmit();
                // }
                // update the isLoading of that element back to false to remove the circularProgress element
                updateSingleIsLoadingElement(clickedIdx, false);
            }, 350);
        }, 0);
    }

    return props.data.map((item, index) => {
        const finalOnClickTouchHandler = () => onSingleItemClickHandler(index);

        const infoClickHandler = (e) => {
            e?.preventDefault();
            e?.stopPropagation();
            toggleSingleInfoElement(index)
        };

        const hideInfo = () => hideSingleInfoElement(index);

        return (
            <SingleListItemWrapper
                key={index}
                item={item}
                index={index}
                hideInfo={hideInfo}
                currLang={i18n.language}
                visibleList={props.visibleList}
                infoClickHandler={infoClickHandler}
                optionsListState={optionsListState}
                flexRelatedStyles={isMobile() ? Styles.SingleListItemMobile() : Styles.SingleListItem()}
                highlightedList={props.highlightedList}
                setOptionsListState={setOptionsListState}
                onClickTouchHandler={finalOnClickTouchHandler}
            />
        );
    })
}

export default OptionsList;