/**
 * 11/30/2023, Joshua Brusa: The speechly api is getting deprecated. Need to
 * switch to this: https://github.com/aws/aws-sdk-js-v3/tree/master/clients/client-transcribe-streaming
 * Use the browserSupportsSpeechRecognition value from the useSpeechRecognition
 * hook to determine if the browser supports speech recognition. If it does not
 * use the aws service instead. Also split the logic for the speech recognition
 * and handle listen they should function independently.
 */

import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren, useEffect, useState, useContext, useCallback } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { Mic, MicOff } from '../Assets/Icons';
import { Style } from '../Styles/AudioRecorderStyles';
import axiosCall from '../Services/axios';
import { IFooterContext, FooterContext } from '@contexts/FooterContext';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import { MorphType } from '@common/MorphType';
import ThinClientUtils from '@common/ThinClientUtils';
import { useUserContext } from '@contexts/user';
import ARStyles from '@styles/ARStyles.module.css';
import { ResetFooterUserAction } from '@common/common';
import { ChatType } from '@common/ChatType';
import useEventBusListener from '@common/hooks/useEventBusListener';

const AudioRecorder: React.FC<PropsWithChildren<any>> = (props) => {
  const {
    setAudioBlob,
    setIsRecording,
    setUserMicOff,
    resetMorph,
    operatorData,
    setOpen,
    setIconToShow,
    setShowPermissionReminder,
    isRecording,
    userMicOff,
    audioBlob,
    recipients,
    leftOfInput,
    selectedFiles,
    fieldAttributeDescription,
  } = props;

  const { _id, isOperator } = useUserContext();

  const { allConsentsGiven, handleShakingConsent, setErrands } = useRootContext();

  const { morphType, setMorphType, errandType, errandId } = useContext<IErrandContext>(ErrandContext);

  const { chatInputFieldRef } = useContext<IFooterContext>(FooterContext);

  const { i18n } = useTranslation();
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const [recorder, setMediaRecorder] = useState(null);
  const [hasTyped, setHasTyped] = useState(false);

  const handleTurnOffMic = useCallback(async () => {
    setAudioBlob(null);
    resetTranscript();
    setIsRecording(false);
    setUserMicOff(true); // User wants to stop recording
    // recorder.stop();
    SpeechRecognition.abortListening();
    SpeechRecognition.stopListening();
    resetMorph();
    setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errandId);

      if (chatObj) {
        ResetFooterUserAction(chatObj);
      }

      return [...prev]; // spread to trigger dependency arrays as state was modified
    });
  }, [errandId, resetMorph, resetTranscript, setAudioBlob, setErrands, setIsRecording, setUserMicOff]);

  const MediaRecorderInit = useCallback(
    async (preferredFormats = ['audio/mp4', 'audio/webm', 'audio/ogg', 'audio/wav']) => {
      try {
        // Request microphone permission and get the audio stream
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        // Try each format until one works
        for (const mimeType of preferredFormats) {
          try {
            return new MediaRecorder(stream, { mimeType });
          } catch (err) {
            console.warn(`MimeType ${mimeType} not supported, trying next...`);
          }
        }

        // Fallback if no format works
        console.error('No preferred formats supported, using default MediaRecorder settings.');

        return new MediaRecorder(stream);
      } catch (error) {
        console.error('Error initializing MediaRecorder:', error);
        throw new Error('Microphone access denied or MediaRecorder not supported.');
      }
    },
    []
  );

  /**
   * Starts recording and transcribing when the user clicks on the
   * microphone icon. Store the audio chunks in a component buffer.
   * We can always extract the current transcribe from the SpeechRecognition
   * transcript buffer and load it into the message input box using a
   * useEffect function.
   */
  const handleListen = useCallback(async () => {
    // Thin Client specific event to request Camera access on click
    if (ThinClientUtils.isThinClient()) {
      window.dispatchEvent(
        new CustomEvent('micAccessEvent', {
          detail: {
            key: 'micAccessEvent',
          },
        })
      );
    }
    if (setOpen) {
      setOpen(false);
    }
    // show consent notification if needed
    if (!allConsentsGiven && operatorData === undefined) {
      handleShakingConsent();
      return;
    }
    try {
      setUserMicOff(false); // User wants to start recording again

      const mediaRecorder = await MediaRecorderInit();

      let chunks = [];

      mediaRecorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks);
        setAudioBlob(blob);
        chunks = [];
      };

      mediaRecorder.start(1000);

      SpeechRecognition.startListening({
        continuous: true, // false caused recording to stop upon verbal pauses
        language: i18n.language,
      });

      setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === errandId);

        if (chatObj) {
          ResetFooterUserAction(chatObj, 'Recording...');
        }

        setIsRecording(true);
        setMediaRecorder(mediaRecorder);
        setMorphType(MorphType.Recording);

        return [...prev]; // spread to trigger dependency arrays as state was modified
      });
    } catch (err) {
      console.error(`The following error occurred: ${err}`);
      setIconToShow('mic');
      setShowPermissionReminder(true);
    }
  }, [
    MediaRecorderInit,
    errandId,
    i18n.language,
    operatorData,
    setAudioBlob,
    setErrands,
    setIconToShow,
    setIsRecording,
    setOpen,
    setShowPermissionReminder,
    setUserMicOff,
    allConsentsGiven,
    handleShakingConsent,
    setMorphType,
  ]);

  const handleInputChange = useCallback((e) => {
    const inputValue = e?.detail?.value ?? null;
    if (inputValue && inputValue.length > 0) {
      setHasTyped(true);
    } else {
      setHasTyped(false);
    }
  }, []);

  useEventBusListener('footer_immidiate_user_value_updated', handleInputChange, []);

  /**
   * Stop recording and transcribing when the user toggles the
   * microphone icon. Stop all recording and speech to text
   * services but keep the audio file and transcript in component
   * buffers because they can submit them to the backend if the
   * send text arrow is pressed.
   */

  // This useEffect ensures that despite 'continuous' listening, it will stop
  // if the morphType is not Recording anymore.
  useEffect(() => {
    if (!listening || morphType === MorphType.Recording) return;
    SpeechRecognition.stopListening();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening]);

  useEffect(() => {
    if (!isRecording && recorder?.state !== 'inactive') {
      recorder?.stop();
      SpeechRecognition?.abortListening();
      SpeechRecognition?.stopListening();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording, recorder, SpeechRecognition]);

  useEffect(() => {
    (async () => {
      if (!userMicOff && audioBlob?.size > 0 && audioBlob?.size < Number(process.env.REACT_APP_FILE_SIZE_LIMIT)) {
        const formData = new FormData();
        formData.append('files', audioBlob, 'audio');
        formData.append('initialMimeType', recorder?.mimeType);
        formData.append('user', _id);
        formData.append('recipients', recipients?.length > 0 ? recipients.join(',') : []);
        formData.append('userType', operatorData ? 'Operator' : 'User');
        formData.append('value', transcript?.toLowerCase());
        const addAudioBlob = {
          url: `chat/${errandId}/audio`,
          method: 'POST',
          data: formData,
        };

        try {
          await axiosCall(addAudioBlob);
        } catch (error) {
          console.error(error);
        }

        setErrands((prev) => {
          const chatObj = prev.find((e) => e._id === errandId);

          if (chatObj) {
            ResetFooterUserAction(chatObj);
          }

          setAudioBlob(null);
          resetTranscript();
          return [...prev]; // spread to trigger dependency arrays as state was modified
        });
      }
    })();
    return () => {
      setAudioBlob(null);
      resetTranscript();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioBlob, _id]);

  if (leftOfInput) {
    return (
      <div className={ARStyles.micOff} onClick={handleTurnOffMic}>
        <MicOff />
      </div>
    );
  } else {
    return (
      <div
        className={
          hasTyped ||
          (chatInputFieldRef.current?.unformattedValue !== '' && !isRecording) ||
          selectedFiles.length > 0 ||
          (morphType !== MorphType.None && morphType !== MorphType.DownloadAppBanner) ||
          fieldAttributeDescription === 'BOOLEAN' ||
          fieldAttributeDescription === 'DROPDOWN' ||
          errandType === ChatType.Conditions
            ? ARStyles.hide
            : ARStyles.show
        }
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      >
        <Style onClick={isRecording ? handleTurnOffMic : handleListen}>
          <div
            className={[
              ARStyles.micContainer,
              ...(!isOperator && !allConsentsGiven ? [ARStyles.disable] : []),
              ...(morphType === MorphType.DownloadAppBanner ? [ARStyles.shift] : []),
            ].join(' ')}
          >
            <Mic className={ARStyles.mic} />
          </div>
        </Style>
      </div>
    );
  }
};

export default AudioRecorder;
