import { useRef } from 'react';

const DEFAULT_THRESHOLD = 10;

const useTap = (onTap: (event: React.TouchEvent) => void, threshold = DEFAULT_THRESHOLD) => {
    const touchStartX = useRef(0);
    const touchStartY = useRef(0);

    const handleTouchStart = (event: React.TouchEvent) => {
        const touch = event.touches[0];
        touchStartX.current = touch.clientX;
        touchStartY.current = touch.clientY;
    };

    const handleTouchEnd = (event: React.TouchEvent) => {
        const touch = event.changedTouches[0];
        const deltaX = Math.abs(touch.clientX - touchStartX.current);
        const deltaY = Math.abs(touch.clientY - touchStartY.current);

        // Call `onTap` only if the movement is within the threshold
        if (deltaX <= threshold && deltaY <= threshold) {
            onTap(event);
        }
    };

    return { onTouchStart: handleTouchStart, onTouchEnd: handleTouchEnd };
};

export default useTap;
