
import { useEffect, useRef } from "react";

const useResizeObserverThrottled = (ref, callback) => {
    const previousDimensions = useRef({ width: null, height: null });
    const timeoutId = useRef(null);

    useEffect(() => {
        if (!ref?.current) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                const prevWidth = previousDimensions.current.width;
                const prevHeight = previousDimensions.current.height;

                if (width !== prevWidth || height !== prevHeight) {
                    // Clear the previous debounce timeout
                    clearTimeout(timeoutId.current);

                    // Debounce the callback execution
                    timeoutId.current = setTimeout(() => {
                        previousDimensions.current = { width, height };
                        callback({ width, height });
                    }, 50);
                }
            }
        });

        const element = ref.current;
        resizeObserver.observe(element);

        return () => {
            clearTimeout(timeoutId.current);
            resizeObserver.unobserve(element);
            resizeObserver.disconnect();
        };
    }, [ref, callback]);
};

export default useResizeObserverThrottled;
